import React, { Component } from 'react'
import { getVendors, deleteVendor, getVendorDetails } from '../../../api/vendor';
import { getCategory } from '../../../api/category';
import AddVendor from './Add';
import DeleteVendor from './Delete';
import EditVendor from './Edit';
import { getVouchers } from '../../../api/voucher';
import VouchersOfVendor from './Voucher';
import CommonPagination from '../../commonComponents/CommonPagination';
import CommonAlert from '../../commonComponents/CommonAlert';
import GenerateQRCode from './GenerateQRCode'
import { LANDING_PAGE_URL } from '../../../config';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';

class Vendor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vendors: [],
            total: 0,
            noOfPages: 0,
            perPage: 0,
            selectedPage: 1,
            categories: [],
            searchKey: '',
            addVendorModal: false,
            editVendorModal: false,
            editVendor: {},
            deleteVendorId: 0,
            deleteVendorModal: false,
            vouchersOfVendor: [],
            voucherModal: false,
            message: '',
            messageType: '',
            categoryId: 0,
            qrCodeLink: '',
            qrCodeModal: false,
            qrCodeVendor: '',
            openCategory: 0,
        }
    }

    componentDidMount = () => {
        this.listVendors()
        const params = {
            count: 50,
            status: "active"
        }
        getCategory(params).then((response) => {
            this.setState({
                categories: response.data.categories
            })
        }).catch((error) => {
            this.showAlert('danger', error.message)
        })
    }

    listVendors = (page = this.state.selectedPage, title = this.state.searchKey, category = this.state.categoryId) => {
        let params = {
            title,
            page,
            category,
            count: 15
        }
        getVendors(params).then((response) => {
            this.setState({
                vendors: response.data.vendors,
                total: response.data.total,
                noOfPages: response.data.noOfpages,
                perPage: response.data.perPage,
                selectedPage: page
            })
        })
    }

    searchVendor = (searchKey) => {
        this.setState({
            searchKey,
        }, () => {
            this.listVendors(1)
        })
    }

    getVendorVouchers = (vendorId) => {
        let params = {
            vendorId
        }
        getVouchers(params).then((response) => {
            this.setState({
                vouchersOfVendor: response.data.vouchers
            }, () => {
                this.toggleVoucherModal()
            })
        })
    }

    toggleVoucherModal = () => {
        this.setState(prevState => ({
            voucherModal: !prevState.voucherModal
        }))
    }


    showAlert = (messageType, message) => {
        this.listVendors()
        this.setState({
            message,
            messageType
        }, () => {
            setTimeout(this.disableAlert, 3000)
        })
    }

    toggleAddModal = () => {
        this.setState(prevState => ({
            addVendorModal: !prevState.addVendorModal
        }))
    }

    disableAlert = () => {
        this.setState({
            message: '',
            messageType: ''
        })
    }

    editVendorDetail = (id) => {
        getVendorDetails(id).then((response) => {
            this.setState({
                editVendor: response.data
            }, () => {
                this.toggleEditModal()
            })
        })
    }

    toggleEditModal = () => {
        this.setState(prevState => ({
            editVendorModal: !prevState.editVendorModal
        }))
    }

    deleteVendorDetail = (id) => {
        this.setState({
            deleteVendorId: id
        }, () => {
            this.toggleDeleteModal()
        })
    }

    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteVendorModal: !prevState.deleteVendorModal
        }))
    }

    submitDeleteVendor = () => {
        deleteVendor(this.state.deleteVendorId).then((response) => {
            this.toggleDeleteModal()
            this.showAlert('success', response.message)
        }).catch((error) => {
            this.toggleDeleteModal()
            this.showAlert('danger', error.message)
        })
    }

    handlePageClick = (page) => {
        this.listVendors(page.selected + 1)
    }

    searchByCategory = (id) => {
        this.setState({
            categoryId: id
        }, () => {
            this.listVendors(1)
        })
    }

    downloadQRCode = (vendorId, name) => {
        let landingPage = LANDING_PAGE_URL + "?vendorId=" + vendorId
        this.setState({
            qrCodeLink: landingPage,
            qrCodeVendor: name
        }, () => {
            this.toggleQRCodeModal()
        })
    }

    toggleQRCodeModal = () => {
        this.setState(prevState => ({
            qrCodeModal: !prevState.qrCodeModal
        }))
    }

    categoryOpen = (category) => {
        let openCategory = 0
        if (this.state.openCategory != category) {
            openCategory = category
        }
        this.setState({
            openCategory,
        })
    }

    render() {
        const { vendors, categories, noOfPages, selectedPage, perPage, searchKey, addVendorModal, editVendorModal, editVendor, deleteVendorModal, voucherModal, vouchersOfVendor, message, messageType, categoryId, qrCodeLink, qrCodeModal, qrCodeVendor, openCategory } = this.state
        return (
            <main id="main">
                <section className="content-wrapper box-shadow">
                    <div className="one-column-right h-100">
                        <div className="box radius-10 tab-style2 h-100">
                            <CommonAlert color={messageType} show={!!messageType} message={message} />
                            <div className="box-header px-3 pt-4 pb-3">
                                <div className="y-center">
                                    <div className="col pl-0">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h5 className="pl-2">Manage Vendors</h5>
                                            <button className="btn btn-primary bg-rust ml-4" onClick={this.toggleAddModal}>
                                                <i className="fi flaticon-add small fs-10"></i>
                                                <span className="pl-2">Add Vendor</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-auto px-2">
                                        <div className="form-row align-items-center">
                                            <div className="form-group search position-relative mb-0 mr-3">
                                                <input className="form-control pr-4" type="search" placeholder="Search by vendor" value={searchKey} onChange={(e) => this.searchVendor(e.target.value)}
                                                    aria-label="Search" />
                                                <i className="fi flaticon-search position-absolute"></i>
                                            </div>
                                            <div className="form-group form-inline mb-0 mr-3">
                                                <select className="form-control" value={categoryId} onChange={(e) => this.searchByCategory(e.target.value)}>
                                                    <option value="">Search by category</option>
                                                    {
                                                        categories && categories.length > 0 &&
                                                        categories.map((category, index) => {
                                                            return (
                                                                <option key={index} value={category.id}>{category.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body px-3">
                                <div className="card border-0">
                                    <div className="card-body p-0">
                                        <div className="table-responsive radius-10 border scroll">
                                            <table className="table mb-0 table-layout-fixed">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" width="5%">No</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Vendor code</th>
                                                        <th scope="col">Redemption code</th>
                                                        <th scope="col">Status</th>
                                                        {/* <th scope="col">Details</th> */}
                                                        <th scope="col">Location</th>
                                                        <th scope="col">Vouchers</th>
                                                        <th scope="col">QR code</th>
                                                        <th scope="col">Categories</th>
                                                        <th scope="col">Edit</th>
                                                        <th scope="col" className="text-center">Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        vendors && vendors.length > 0 &&
                                                        vendors.map((vendor, index) => {
                                                            return (
                                                                <tr key={vendor.id}>
                                                                    <td>{perPage * (selectedPage - 1) + (index + 1)}</td>
                                                                    <td>{vendor.name}</td>
                                                                    <td>{vendor.vendorCode}</td>
                                                                    <td>{vendor.redemptionCode}</td>
                                                                    <td>{vendor.active ? "Active" : "Inactive"}</td>
                                                                    <td>{vendor.location}</td>
                                                                    <td>
                                                                        <button onClick={() => this.getVendorVouchers(vendor.id)} className="btn btn-primary bg-rust px-2">
                                                                            View
                                                                        </button>
                                                                    </td>
                                                                    <td>
                                                                        <button onClick={() => this.downloadQRCode(vendor.id, vendor.name)} className="btn btn-primary bg-rust px-2">
                                                                            Preview
                                                                        </button>
                                                                    </td>
                                                                    <td className="collapse-td">
                                                                        <Button color="primary" onClick={() => this.categoryOpen(vendor.id)}>View</Button>
                                                                        <div className={"categories-collapse" + (!(vendor.id == openCategory) ? " collapse" : "")}>
                                                                            <ListGroup>
                                                                                {
                                                                                    vendor.categories && vendor.categories.length > 0 &&
                                                                                    vendor.categories.map((category) => {
                                                                                        return (
                                                                                            <ListGroupItem key={category.id}>{category.name}</ListGroupItem>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ListGroup>
                                                                        </div>
                                                                    </td>
                                                                    <td className="c-clay-creek edit"><i onClick={(e) => this.editVendorDetail(vendor.id)} className="fi flaticon-write"></i></td>
                                                                    <td className="text-center delete"><i onClick={() => this.deleteVendorDetail(vendor.id)} className="fi c-red flaticon-delete" ></i></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        vendors && vendors.length == 0 &&
                                                        <tr>
                                                            <td colSpan="8" className="text-center">
                                                                <h6 className="mt-3">No vendors are added</h6>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <CommonPagination onPageChange={this.handlePageClick} pageCount={noOfPages} selectedPage={selectedPage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <AddVendor modal={addVendorModal} toggle={this.toggleAddModal} categories={categories} showAlert={this.showAlert} />
                <EditVendor modal={editVendorModal} toggle={this.toggleEditModal} vendor={editVendor} showAlert={this.showAlert} categories={categories} />
                <DeleteVendor modal={deleteVendorModal} toggle={this.toggleDeleteModal} submitDeleteVendor={this.submitDeleteVendor} />
                <VouchersOfVendor modal={voucherModal} toggle={this.toggleVoucherModal} vouchers={vouchersOfVendor} />
                <GenerateQRCode toggle={this.toggleQRCodeModal} modal={qrCodeModal} link={qrCodeLink} vendor={qrCodeVendor} />
            </main>
        )
    }
}

export default Vendor