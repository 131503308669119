import React, { Component } from 'react'
import CommonPagination from '../../commonComponents/CommonPagination';
import AddPackage from './Add';
import DeletePackage from './Delete';
import EditPackage from './Edit';
import { getPackages, deletePackage } from '../../../api/package';
import CommonAlert from '../../commonComponents/CommonAlert';

class Package extends Component {

    constructor(props) {
        super(props);
        this.state = {
            packages: [],
            total: 0,
            noOfPages: 0,
            perPage: 0,
            selectedPage: 1,
            addPackageModal: false,
            editPackage: {},
            editPackageModal: false,
            deletePackageId: 0,
            deletePackageModal: false,
            searchKey: '',
            message: '',
            messageType: ''
        }
    }

    componentDidMount = () => {
        this.listPackages()
    }

    listPackages = (page = this.state.selectedPage, key = this.state.searchKey) => {
        let params = {
            title: key,
            page,
            count: 15
        }
        getPackages(params).then((response) => {
            this.setState({
                packages: response.data.packages,
                total: response.data.total,
                noOfPages: response.data.noOfPages,
                perPage: response.data.perPage,
                selectedPage: page
            })
        })
    }

    searchPackage = (key) => {
        this.setState({
            searchKey: key
        }, () => {
            this.listPackages(1)
        })
    }

    toggleAddPackage = () => {
        this.setState(prevState => ({
            addPackageModal: !prevState.addPackageModal
        }))
    }

    showAlert = (message, messageType) => {
        this.listPackages()
        this.setState({
            message: message,
            messageType: messageType
        }, () => {
            setTimeout(this.dismissAlert, 3000)
        })
    }

    dismissAlert = () => {
        this.setState({
            message: '',
            messageType: ''
        })
    }

    editPackageDetails = (packageDetails) => {
        this.setState({
            editPackage: packageDetails
        }, () => {
            this.toggleEditPackageModal()
        })
    }

    toggleEditPackageModal = () => {
        this.setState(prevState => ({
            editPackageModal: !prevState.editPackageModal
        }))
    }

    deleteVendorDetails = (id) => {
        this.setState({
            deletePackageId: id
        }, () => {
            this.toggleDeleteModal()
        })
    }

    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deletePackageModal: !prevState.deletePackageModal
        }))
    }

    submitDeletePackage = () => {
        this.toggleDeleteModal()
        deletePackage(this.state.deletePackageId).then(() => {
            this.listPackages()
            this.showAlert('Package deleted successfully', 'success')
        }).catch((error) => {
            this.showAlert(error.message, 'danger')
        })
    }

    handlePageClick = (page) => {
        this.listPackages(page.selected + 1)
    }

    render() {
        const { packages, searchKey, perPage, noOfPages, selectedPage, addPackageModal, editPackageModal, editPackage, deletePackageModal, message, messageType } = this.state
        return (
            <main id="main">
                <section className="content-wrapper box-shadow">
                    <div className="one-column-right h-100">
                        <div className="box radius-10 tab-style2 h-100">
                            <CommonAlert color={messageType} show={!!messageType} message={message} />
                            <div className="box-header px-3 pt-4 pb-3">
                                <div className="y-center">
                                    <div className="col pl-0">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h5 className="pl-2">Manage Packages</h5>
                                            <button className="btn btn-primary bg-rust ml-4" onClick={this.toggleAddPackage}>
                                                <i className="fi flaticon-add small fs-10"></i>
                                                <span className="pl-2">Add Package</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-auto px-2">
                                        <div className="form-row align-items-center">
                                            <div className="form-group search position-relative mb-0 mr-3">
                                                <input className="form-control pr-4" value={searchKey} onChange={(e) => this.searchPackage(e.target.value)} type="search" placeholder="Search by package"
                                                    aria-label="Search" />
                                                <i className="fi flaticon-search position-absolute"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body px-3">
                                <div className="card border-0">
                                    <div className="card-body p-0">
                                        <div className="table-responsive radius-10 border scroll">
                                            <table className="table mb-0 table-layout-fixed">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="text-center" width="5%">No</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Package Type</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Validity (days)</th>
                                                        <th scope="col">Vouchers</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col" width="10%" className="text-center">Edit</th>
                                                        <th scope="col" width="10%" className="text-center">Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        packages && packages.length > 0 &&
                                                        packages.map((element, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="text-center">{perPage * (selectedPage - 1) + (index + 1)}</td>
                                                                    <td>{element.name}</td>
                                                                    <td>{element.packageType == "iOS" ? "iOS" : element.packageType.charAt(0).toUpperCase() + element.packageType.slice(1)}</td>
                                                                    <td>{element.amount} {element.currency}</td>
                                                                    <td>{element.validity}</td>
                                                                    <td>{element.voucherCount}</td>
                                                                    {
                                                                        element.active == true &&
                                                                        <td>Active</td>
                                                                    }
                                                                    {
                                                                        element.active == false &&
                                                                        <td>Inactive</td>
                                                                    }
                                                                    <td className="c-clay-creek text-center edit"><i onClick={() => this.editPackageDetails(element)} className="fi flaticon-write"></i></td>
                                                                    <td className="text-center delete"><i onClick={() => this.deleteVendorDetails(element.id)} className="fi c-red flaticon-delete" ></i></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        packages && packages.length == 0 &&
                                                        <tr>
                                                            <td colSpan="8" className="text-center">
                                                                <h6 className="mt-3">No packages are added</h6>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <CommonPagination onPageChange={this.handlePageClick} pageCount={noOfPages} selectedPage={selectedPage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <AddPackage modal={addPackageModal} toggle={this.toggleAddPackage} showAlert={this.showAlert} />
                <EditPackage modal={editPackageModal} toggle={this.toggleEditPackageModal} package={editPackage} showAlert={this.showAlert} />
                <DeletePackage modal={deletePackageModal} toggle={this.toggleDeleteModal} submitDeletePackage={this.submitDeletePackage} />
            </main>
        )
    }
}

export default Package