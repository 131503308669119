import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import randomstring from 'randomstring';
import { addCategory, editCategory } from '../../../../api/category';
import { getCognitoToken } from '../../../../api/aws';
import { setBucket } from '../../../../aws/awsupload'

class ManageCategory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      imageURL: '',
      categoryId: 0,
      imageFile: '',
      imageFileName: '',
      imageChanged: false,
      cognitoToken: '',
      identityID: '',
      status: '',
      buttonDisabled: false,
    }
  }

  emptyState = () => {
    this.setState({
      name: '',
      imageURL: '',
      imageFile: '',
      imageFileName: '',
      imageChanged: false,
      cognitoToken: '',
      identityID: '',
      status: '',
      buttonDisabled: false,
    })
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.modal != this.props.modal) {
      if (this.props.mode == 'edit') {
        this.prevStatus = this.props.active ? "active" : "inactive"
        this.setState({
          name: this.props.name,
          imageURL: this.props.imageURL,
          categoryId: this.props.categoryId,
          imageFileName: '',
          imageChanged: false,
          cognitoToken: '',
          identityID: '',
          status: this.prevStatus,
          buttonDisabled: false,
        })
      } else {
        this.emptyState()
      }
    }
  }

  onInputChange = (value, key) => {
    this.setState({
      [key]: value
    })
  }

  handleImageChange = (event) => {
    if (event) {
      this.setState({
        imageURL: URL.createObjectURL(event.target.files[0]),
        imageFile: event.target.files[0],
        imageFileName: randomstring.generate() + "." + event.target.files[0].type.split('/')[1],
        imageChanged: true
      })
    }
  }


  submitCategory = () => {
    this.setState({
      buttonDisabled: true
    }, () => {
      if (!this.state.name) {
        this.props.showAlert("Enter category name", "danger")
        this.setState({
          buttonDisabled: false
        })
        return
      }
      let body = {
        name: this.state.name
      }
      if (this.prevStatus != this.state.status) {
        body.status = this.state.status
      }
      let bucket = {}
      if (this.state.imageChanged) {
        body.imageURL = 'category/' + this.state.imageFileName
        let params = {}
        params.identityId = ""
        getCognitoToken(params).then((response) => {
          this.setState({
            cognitoToken: response.data.cognitoToken,
            identityID: response.data.identityId
          }, () => {
            // set S3 bucket after retreiving token successfully
            bucket = setBucket(this.state.cognitoToken)

            let file = this.state.imageFile
            let fileKey = `category/${this.state.imageFileName}`;
            let params = {
              Key: fileKey,
              ContentType: file.type,
              Body: file,
              ACL: 'public-read',
            };
            bucket.putObject(params).on('httpUploadProgress', (progress) => {
              // console.log("progress", progress.loaded * 100 / progress.total)
              if (parseInt((progress.loaded * 100) / progress.total) === 100) {
                this.submitCategoryAPI(body)
              }
            }).send((err) => {
              if (err) {
                this.props.showAlert("An unexpected error occured", 'danger')
                console.log("Error while uploading video to s3 bucket in add category ", err)
                this.setState({
                  buttonDisabled: false
                })
              }
            })
          })
        }).catch((error) => {
          this.props.showAlert("An unexpected error occured", 'danger')
          console.log("Error while getting cognito token ", error)
          this.setState({
            buttonDisabled: false
          })
        })
      } else {
        this.submitCategoryAPI(body)
      }
    })
  }


  submitCategoryAPI = (body) => {
    if (this.props && this.props.mode == 'edit') {
      body.status = this.state.status
      editCategory(this.props.id, body).then((response) => {
        this.props.toggle()
        this.props.showAlert(response.message, 'success')
      }).catch((error) => {
        this.props.showAlert(error.message, 'danger')
      }).finally(() => {
        this.setState({
          buttonDisabled: false
        })
      })
    } else {
      addCategory(body).then((response) => {
        this.props.toggle()
        this.props.showAlert(response.message, 'success')
      }).catch((error) => {
        this.props.showAlert(error.message, 'danger')
      }).finally(() => {
        this.setState({
          buttonDisabled: false
        })
      })
    }
  }

  render() {
    const { name, imageURL, status, buttonDisabled } = this.state
    return (
      <Modal isOpen={this.props.modal}>
        <ModalHeader toggle={this.props.toggle}>Manage Category</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup className="mb-4">
              <Label for="name">Name</Label>
              <Input name="name" value={name} type="text" placeholder="Enter category" onChange={(e) => this.onInputChange(e.target.value, 'name')} />
            </FormGroup>
            {
              this.props.mode == 'edit' &&
              <FormGroup className="mb-4">
                <Label for="status">Status</Label>
                <Input type="select" name="status" id="status" value={status} onChange={(e) => this.onInputChange(e.target.value, 'status')}>
                  <option value="" disabled>Select status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Input>
              </FormGroup>
            }
            <FormGroup>
              <Label for="image">Image</Label>
              <span className="card uploadImgwrap2 d-flex align-items-center justify-content-center position-relative">
                <Input name="image" type="file" accept="image/*" placeholder="Select image" onChange={this.handleImageChange} className="addModalChooseImage h-100" />
                {
                  imageURL &&
                  <img className="mx-auto py-3" src={imageURL} alt="" />
                }
              </span>
              <small className="d-flex mt-3">Please click on the above box to add/edit image</small>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.submitCategory} disabled={buttonDisabled}>Submit</Button>
          <Button color="secondary" className="ml-2" onClick={this.props.toggle} onClick={this.props.toggle} disabled={buttonDisabled}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ManageCategory
