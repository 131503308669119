import fetch from './handler'

export const getPackages = (params) => {
    return fetch({
        method: 'GET',
        url: '/package',
        params
    })
}

export const addPackage = (body) => {
    return fetch({
        method: 'POST',
        url: '/package',
        jsonData: true,
        body
    })
}

export const editPackage = (id, body) => {
    const url = `/package/${id}`
    return fetch({
        method: 'PUT',
        url: url,
        jsonData: true,
        body
    })
}

export const deletePackage = (id) => {
    const url = `/package/${id}`
    return fetch({
        method: 'DELETE',
        url
    })
}

export const getPackageReport = (params) => {
    return fetch({
        method: 'GET',
        url: `/package/report`,
        params,
    })
}

export const extendPackage = (id, body) => {
    return fetch({
        method: 'POST',
        url: `/customer/package/${id}/extend`,
        body,
    })
}