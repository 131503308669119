import fetch from './handler'

export const getCustomers = (params) => {
    return fetch({
        method: 'GET',
        url: '/customer',
        params,
    })
}

export const getCustomerDetails = (id) => {
    return fetch({
        method: 'GET',
        url: `/customer/${id}`,
    })
}

export const deleteCustomer = (id) => {
    return fetch({
        method: 'DELETE',
        url: `/customer/${id}`
    })
}

export const editCustomer = (id, body) => {
    return fetch({
        method: 'PUT',
        url: `/customer/${id}`,
        body,
    })
}

export const getTransactions = (params) => {
    return fetch({
        method: 'GET',
        url: '/customer/transaction',
        params
    })
}