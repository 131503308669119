import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class VouchersOfVendor extends Component {
    render() {
        return (
            <Modal isOpen={this.props.modal}>
                <ModalHeader toggle={this.props.toggle}>Vouchers</ModalHeader>
                <ModalBody className="p-2">
                    {
                        this.props && this.props.vouchers && this.props.vouchers.length > 0 &&
                        <table className="table mb-0 table-layout-fixed">
                            <thead>
                                <tr>
                                    <th scope="col" width="10%">No</th>
                                    <th scope="col">Offer</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Redeemed Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.vouchers.map((voucher, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{voucher.offer}</td>
                                                <td>{voucher.total}</td>
                                                <td>{voucher.redeemedCount}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    }
                    {
                        this.props && this.props.vouchers && this.props.vouchers.length == 0 &&
                        <h6 className="mt-3 text-center">No vouchers are added for this vendor</h6>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.props.toggle}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default VouchersOfVendor