import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, InputGroup, InputGroupText } from 'reactstrap';
import randomstring from 'randomstring';
import { addPackage } from '../../../../api/package';
import { getCognitoToken } from '../../../../api/aws';
import { setBucket } from '../../../../aws/awsupload'

class AddPackage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            amount: 0.0,
            validity: 0,
            voucherCount: 0,
            description: '',
            imageURL: '',
            imageFile: '',
            imageFileName: '',
            cognitoToken: '',
            identityID: '',
            buttonDisabled: false,
            packageType: '',
            productId: '',
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.modal != this.props.modal) {
            this.setState({
                name: '',
                amount: 0.0,
                validity: 0,
                voucherCount: 0,
                description: '',
                imageURL: '',
                imageFile: '',
                imageFileName: '',
                cognitoToken: '',
                identityID: '',
                buttonDisabled: false,
                packageType: '',
                productId: '',
            })
        }
    }

    onInputChange = (value, key) => {
        this.setState({
            [key]: value
        })
    }

    handleImageChange = (event) => {
        this.setState({
            imageURL: URL.createObjectURL(event.target.files[0]),
            imageFile: event.target.files[0],
            imageFileName: randomstring.generate() + "." + event.target.files[0].type.split('/')[1]
        })
    }

    submitAddPackage = () => {
        this.setState({
            buttonDisabled: true
        }, () => {
            const { name, amount, validity, voucherCount, packageType, productId, description } = this.state
            let valid = true
            let alertMessage = ""
            if (!name) {
                valid = false
                alertMessage = 'Please enter a valid name'
            }
            if (!amount || amount < 0) {
                valid = false
                alertMessage = 'Please enter a valid amount'
            }
            if (!validity || validity < 0) {
                valid = false
                alertMessage = 'Please enter a valid validity'
            }
            if (!voucherCount || voucherCount < 0) {
                valid = false
                alertMessage = 'Please enter a valid voucher count'
            }
            if (!description) {
                valid = false
                alertMessage = 'Please enter a valid description'
            }
            if (!packageType) {
                valid = false
                alertMessage = 'Please select package type'
            }
            if ((packageType == 'iOS' || packageType == 'android') && !productId) {
                valid = false
                alertMessage = 'Please enter product ID'
            }
            if (!valid) {
                this.props.showAlert(alertMessage, 'danger')
                this.setState({
                    buttonDisabled: false
                })
                return
            }
            let body = {
                name,
                amount: parseInt(amount, 10),
                currency: "AUD",
                validity: parseInt(validity, 10),
                voucherCount: parseInt(voucherCount, 10),
                packageType,
                productId,
                description
            }
            let bucket = {}
            if (this.state.imageURL != "") {
                body.imageURL = "package/" + this.state.imageFileName
                let params = {}
                params.identityId = ""
                getCognitoToken(params).then((response) => {
                    this.setState({
                        cognitoToken: response.data.cognitoToken,
                        identityID: response.data.identityId
                    }, () => {
                        // set S3 bucket after retreiving token successfully
                        bucket = setBucket(this.state.cognitoToken)

                        let file = this.state.imageFile
                        let fileKey = `package/${this.state.imageFileName}`;
                        let params = {
                            Key: fileKey,
                            ContentType: file.type,
                            Body: file,
                            ACL: 'public-read',
                        };
                        bucket.putObject(params).on('httpUploadProgress', (progress) => {
                            // console.log("progress", progress.loaded * 100 / progress.total)
                            if (parseInt((progress.loaded * 100) / progress.total) === 100) {
                                this.submitAddPackageAPI(body)
                            }
                        }).send((err) => {
                            if (err) {
                                console.log("Error while uploading video to s3 bucket in add category ", err)
                                this.props.showAlert("An unexpected error occured", 'danger')
                                this.setState({
                                    buttonDisabled: false
                                })
                            }
                        })
                    })
                }).catch((error) => {
                    console.log("Error while getting cognito token ", error)
                    this.props.showAlert("An unexpected error occured", 'danger')
                    this.setState({
                        buttonDisabled: false
                    })
                })
            } else {
                this.submitAddPackageAPI(body)
            }
        })
    }

    submitAddPackageAPI = (body) => {
        addPackage(body).then(() => {
            this.props.toggle()
            this.props.showAlert('Package added successfully', 'success')
        }).catch((error) => {
            this.props.showAlert(error.message, 'danger')
        }).finally(() => {
            this.setState({
                buttonDisabled: false
            })
        })
    }

    render() {

        const { name, amount, validity, voucherCount, imageURL, buttonDisabled, packageType, productId, description } = this.state
        return (
            <Modal isOpen={this.props.modal}>
                <ModalHeader toggle={this.props.toggle}>Add Package</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Input name="name" type="text" placeholder="Enter Package name" value={name} onChange={(e) => this.onInputChange(e.target.value, 'name')} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="amount">Amount</Label>
                            <InputGroup>
                                <Input name="amount" type="number" placeholder="Enter Package amount" value={amount} onChange={(e) => this.onInputChange(e.target.value, 'amount')} />
                                <InputGroupText>AUD</InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label for="validity">Validity</Label>
                            <InputGroup>
                                <Input name="validity" type="number" placeholder="Enter Validity" value={validity} onChange={(e) => this.onInputChange(e.target.value, 'validity')} />
                                <InputGroupText>Days</InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label for="voucher">Voucher Count</Label>
                            <Input name="voucher" type="number" placeholder="Enter Voucher count" value={voucherCount} onChange={(e) => this.onInputChange(e.target.value, 'voucherCount')} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Input name="description" type="text" placeholder="Enter description" value={description} onChange={(e) => this.onInputChange(e.target.value, 'description')} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="vendorSelect">Package Type</Label>
                            <Input type="select" name="packageType" id="packageType" value={packageType} onChange={(e) => this.onInputChange(e.target.value, 'packageType')}>
                                <option value="">Select package type</option>
                                <option value="regular">Regular</option>
                                <option value="iOS">IOS</option>
                                <option value="android">Android</option>
                            </Input>
                        </FormGroup>
                        {
                            packageType == "android" &&
                            <FormGroup>
                                <Label for="name">Android Product ID</Label>
                                <Input name="name" type="text" placeholder="Enter Android package name" value={productId} onChange={(e) => this.onInputChange(e.target.value, 'productId')} />
                            </FormGroup>
                        }
                        {
                            packageType == "iOS" &&
                            <FormGroup>
                                <Label for="name">IOS product ID</Label>
                                <Input name="name" type="text" placeholder="Enter iOS package name" value={productId} onChange={(e) => this.onInputChange(e.target.value, 'productId')} />
                            </FormGroup>
                        }
                        <FormGroup>
                            <Label for="image">Image</Label>
                            <span className="card uploadImgwrap2 d-flex align-items-center justify-content-center position-relative">
                                <Input name="image" type="file" accept="image/*" placeholder="Select image" onChange={this.handleImageChange} className="addModalChooseImage h-100" />
                                {
                                    imageURL &&
                                    <img className="mx-auto py-3" src={imageURL} alt="" />
                                }
                            </span>
                            <small className="d-flex mt-3">Please click on the above box to add image</small>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.submitAddPackage} disabled={buttonDisabled}>Submit</Button>
                    <Button color="secondary" className="ml-2" onClick={this.props.toggle} disabled={buttonDisabled}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default AddPackage