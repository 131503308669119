import React from 'react';
import ReactPaginate from 'react-paginate';

const CommonPagination = (props) => {
    return (
        <div className="card-footer text-right p-0 y-center bg-transparent border-0">
            {
                props.pageCount > 1 &&
                <ReactPaginate
                    previousLabel={'prev'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={props.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={props.onPageChange}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    initialPage={props.selectedPage - 1}
                    forcePage={props.selectedPage - 1}
                />
            }
        </div>
    )
}
export default CommonPagination