import React, { Component } from 'react'
import { Form, Alert } from 'reactstrap';
import { login } from '../../../api/auth';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            alertShow: false,
            alertMessage: ''
        }
    }

    onInputChange = (value, key) => {
        this.setState({
            [key]: value
        })
    }

    disableAlert = () => {
        this.setState({
            alertShow: false,
            alertMessage: ''
        })
    }

    onSubmitLogin = () => {
        let body = {};
        body.email = this.state.email.trim()
        body.password = this.state.password.trim()

        login(body).then((response) => {
            localStorage.setItem("isLoggedIn", "true")
            this.props.history.push('/dashboard')
        }).catch((error) => {
            this.setState({
                alertShow: true,
                alertMessage: error.message
            }, () => {
                setTimeout(this.disableAlert, 2000)
            })
        })
    }

    render() {

        const { email, password } = this.state

        return (
            <div id="login-wrapper">
                <div className="col-12 d-flex flex-column justify-content-center h-100 align-items-center">
                    <div className="login-container">
                        <div className="d-flex">
                            <div className="col login-lhs d-flex">
                                <div className="align-self-center w-100">
                                    <h1 className="py-3">
                                        Please login
                                            <span className=" d-block">Epic Vouchers</span>
                                    </h1>
                                    <Form>
                                        <Alert color="danger" isOpen={this.state.alertShow}>
                                            {this.state.alertMessage}
                                        </Alert>
                                        <div className="form-group py-2">
                                            <label htmlFor="exampleInputEmail1">Email</label>
                                            <input type="email" className="form-control" id="email" value={email}
                                                placeholder="Please enter email" onChange={(e) => this.onInputChange(e.target.value, 'email')} />
                                        </div>
                                        <div className="form-group py-2">
                                            <label htmlFor="password">Password</label>
                                            <input type="password" className="form-control" id="password" value={password}
                                                placeholder="Please enter password" onChange={(e) => this.onInputChange(e.target.value, 'password')} />
                                        </div>
                                        <button type="button" onClick={(e) => { e.preventDefault(); this.onSubmitLogin() }} className="btn bg-primary-rust w-100">LOGIN</button>
                                    </Form>
                                    {/* <a href="" onClick={(e) => { e.preventDefault() }} className="text-center d-block w-100 py-3">Forgot Password ?</a> */}
                                </div>
                            </div>
                            <div className="col-7 login-rhs">
                                {/* <img src="/media/login-img.png" alt="" /> */}
                            </div>
                            {/* <col/> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login