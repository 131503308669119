import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import QRCode from 'qrcode.react'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

class GenerateQRCode extends Component {

    constructor(props) {
        super(props);
        this.state = {
            qrCodeLink: ''
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.modal != prevProps.modal) {
            this.setState({
                qrCodeLink: this.props.link
            })
        }
    }

    downloadQRCode = () => {
        const input = document.getElementById('qr-code')

        let fileName = this.props && this.props.vendor + "_qrcode"

        html2canvas(input).then((canvas) => {
            const image = canvas.toDataURL('image/png', 1.0)
            const pdf = new jsPDF('p', 'mm')
            const pageWidth = pdf.internal.pageSize.getWidth()
            const pageHeight = pdf.internal.pageSize.getHeight()

            const widthRatio = pageWidth / canvas.width;
            const heightRatio = pageHeight / canvas.height;
            const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

            const canvasWidth = canvas.width * ratio;
            const canvasHeight = canvas.height * ratio;

            const marginX = (pageWidth - canvasWidth) / 2;
            const marginY = (pageHeight - canvasHeight) / 2;

            pdf.addImage(image, 'JPEG', marginX, marginY, canvasWidth, canvasHeight);
            pdf.save(fileName)

            this.props.toggle()

        })
    }

    render() {
        const { qrCodeLink } = this.state
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggle} >
                <ModalHeader toggle={this.props.toggle}>QR Code</ModalHeader>
                <ModalBody className=" d-flex justify-content-center">
                    <QRCode
                        id="qr-code"
                        value={qrCodeLink}
                        size={250}
                        level={"H"}
                        includeMargin={true}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" className="mr-2" onClick={this.props.toggle}>Cancel</Button>
                    <Button color="primary" onClick={this.downloadQRCode} >Download</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default GenerateQRCode