import fetch from './handler'

export const getVendors = (params) => {
    return fetch({
        method: 'GET',
        url: '/vendor',
        jsonData: true,
        params
    })
}

export const addVendor = (body) => {
    return fetch({
        method: 'POST',
        url: '/vendor',
        jsonData: true,
        body
    })
}

export const editVendor = (id, body) => {
    const url = `/vendor/${id}`
    return fetch({
        method: 'PUT',
        url: url,
        jsonData: true,
        body
    })
}

export const deleteVendor = (id) => {
    const url = `/vendor/${id}`
    return fetch({
        method: 'DELETE',
        url
    })
}

export const getVendorDetails = (id) => {
    const url = `/vendor/${id}`
    return fetch({
        method: 'GET',
        url
    })
}