let {
    apiRoot,
    awsRoleArn,
    s3BucketName,
    region,
    googleApiKey,
    landingPageURL
} = {};

if (process.env.NODE_ENV === "production") {
    apiRoot = "**APIROOT**";
    awsRoleArn = "**AWS_ROLE_ARN**";
    s3BucketName = "**S3_BUCKET_NAME**";
    region = "**AWS_REGION**";
    googleApiKey = "**GOOGLE_API_KEY**";
    landingPageURL = "**LANDING_PAGE_URL**"
} else if (process.env.NODE_ENV === "development") {
    // apiRoot = "https://epicvouchers-api.hifx.in";
    apiRoot = "http://127.0.0.1:8083";
    // apiRoot = "http://fw-admin-dev.hifx.in:8083";
    awsRoleArn = "arn:aws:iam::978021328205:role/Cognito_stage_ferris_wheelAuth_Role";
    s3BucketName = "fw-admin.hifx.in";
    region = "ap-south-1";
    googleApiKey = "AIzaSyA0hv3BZLN8wIL0_X2RB1JRs5FdShYOFSY";
    landingPageURL = "https://epicvouchers-fe.hifx.in"
}

export const API_ROOT = apiRoot;
export const AWS_ROLE_ARN = awsRoleArn;
export const BUCKET_NAME = s3BucketName;
export const REGION = region;
export const GOOGLE_API_KEY = googleApiKey;
export const LANDING_PAGE_URL = landingPageURL;