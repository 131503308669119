import moment from 'moment';
import React from 'react';
import { Container, Row, Col, Input, Label, Button } from 'reactstrap';
import { deleteCustomer, editCustomer, getCustomerDetails } from '../../../../api/customer';
import { getPackageReport } from '../../../../api/package';
import { getVoucherReport } from '../../../../api/voucher';
import CommonAlert from '../../../commonComponents/CommonAlert';
import CommonConfirmationModal from '../../../commonComponents/CommonConfirmationModal';
import CommonPagination from '../../../commonComponents/CommonPagination';
import ExtendPackage from './ExtendPackage';

class CustomerDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customerDetails: {},
            customerPackages: [],
            customerVouchers: [],
            customerPackagesPageSelected: 1,
            customerPackagesNoOfPages: 1,
            customerVouchersPageSelected: 1,
            customerVouchersNoOfPages: 1,
            customerVouchersPerPage: 0,
            customerPackagesPerPage: 0,
            alertMessage: "",
            alertColor: "",
            alertShow: false,
            searchedVendorName: "",
            searchedVoucherStatus: "",
            searchedPackageName: "",
            searchedPackageStatus: "",
            extendPackageModal: false,
            extendPackageId: 0,
            confirmationModal: false,
            confirmationModalTitle: "",
            confirmationModalBody: null,
            modalAction: "",
            extendPackageCustomerId: 0
        }
        this.customerId = this.props.match.params.id
    }

    componentDidMount = () => {
        this.loadCustomerDetails()
        this.getCustomerPackages()
        this.getCustomerVouchers()
    }

    setAlert = (alertMessage, alertColor) => {
        this.setState({
            alertMessage,
            alertColor,
            alertShow: true,
        }, () => {
            setTimeout(() => {
                this.setState({
                    alertMessage: "",
                    alertColor: "",
                    alertShow: false,
                })
            }, 3000)
        })
    }

    handlePageClickForPackages = (page) => {
        this.getCustomerPackages(page.selected + 1)
    }

    handlePageClickForVouchers = (page) => {
        this.getCustomerVouchers(page.selected + 1)
    }

    loadCustomerDetails = () => {
        getCustomerDetails(this.customerId).then((response) => {
            this.setState({
                customerDetails: response.data
            })
        })
    }

    getCustomerPackages = (page = this.state.customerPackagesPageSelected, packageName = this.state.searchedPackageName, status = this.state.searchedPackageStatus) => {
        const params = {
            page,
            customerId: this.customerId,
            packageName,
            status,
            limit: true
        }
        getPackageReport(params).then((response) => {
            this.setState({
                customerPackages: response.data.packages,
                customerPackagesPageSelected: page,
                customerPackagesNoOfPages: response.data.noOfPages,
                customerPackagesPerPage: response.data.perPage
            })
        }).catch((error) => {
            this.setAlert(error.message, "danger")
        })
    }

    getCustomerVouchers = (page = this.state.customerVouchersPageSelected, vendorName = this.state.searchedVendorName, status = this.state.searchedVoucherStatus) => {
        const params = {
            page,
            customerId: this.customerId,
            vendorName,
            status,
            limit: true
        }
        getVoucherReport(params).then((response) => {
            this.setState({
                customerVouchers: response.data.vouchers,
                customerVouchersPageSelected: page,
                customerVouchersNoOfPages: response.data.noOfPages,
                customerVouchersPerPage: response.data.perPage
            })
        }).catch((error) => {
            this.setAlert(error.message, "danger")
        })
    }

    voucherSearch = (key, value) => {
        this.setState({
            [key]: value,
        }, this.getCustomerVouchers)
    }

    packageSearch = (key, value) => {
        this.setState({
            [key]: value,
        }, this.getCustomerPackages)
    }

    extendPackageModalShow = (extendPackageId, extendPackageCustomerId) => {
        this.setState({
            extendPackageId,
            extendPackageCustomerId,
            extendPackageModal: true
        })
    }

    extendPackageModalClose = () => {
        this.setState({
            extendPackageId: 0,
            extendPackageModal: false,
        })
    }

    changeConfirmationModal = (confirmationModal = false, confirmationModalTitle, confirmationModalBody, modalAction) => {
        this.setState({
            confirmationModal,
            confirmationModalTitle,
            confirmationModalBody,
            modalAction,
        })
    }

    submitConfirmationModal = () => {
        switch (this.state.modalAction) {
            case "delete":
                this.confirmDelete()
                break
            case "activate/deactivate":
                this.confirmStatusChange()
                break
        }
    }

    confirmDelete = () => {
        deleteCustomer(this.customerId).then(() => {
            this.props.history.goBack()
        }).catch((error) => {
            this.setAlert(error.message, "danger")
        })
    }

    confirmStatusChange = () => {
        const body = {
            status: this.state.customerDetails.active ? "inactive" : "active"
        }
        editCustomer(this.customerId, body).then(() => {
            this.changeConfirmationModal(false, "", "", "")
            this.loadCustomerDetails()
            this.setAlert("Customer status changed successfully", "success")
        }).catch((error) => {
            this.setAlert(error.message, "danger")
        })
    }

    render() {
        const { customerDetails, customerPackages, customerVouchers, customerPackagesPageSelected, customerPackagesNoOfPages, customerVouchersPageSelected, customerVouchersNoOfPages, alertMessage, alertColor, alertShow, searchedVendorName, searchedVoucherStatus, searchedPackageName, searchedPackageStatus, extendPackageId, extendPackageModal, confirmationModal, confirmationModalTitle, confirmationModalBody, extendPackageCustomerId, customerPackagesPerPage, customerVouchersPerPage } = this.state
        return (
            <main id="main">
                <CommonAlert message={alertMessage} color={alertColor} show={alertShow} />
                <ExtendPackage isOpen={extendPackageModal} toggle={this.extendPackageModalClose} customerPackageId={extendPackageId} refreshPackageList={this.getCustomerPackages} setAlert={this.setAlert} customerId={extendPackageCustomerId} />
                <CommonConfirmationModal toggle={() => this.changeConfirmationModal(false, "", "")} isOpen={confirmationModal} body={confirmationModalBody} title={confirmationModalTitle} submit={this.submitConfirmationModal} />
                <section className="content-wrapper box-shadow">
                    <div className="one-column-right h-100">
                        <div className="box radius-10 tab-style2 h-100">
                            <div className="box-body p-3 h-100">
                                <div className="card-header p-0 border-0 bg-white">
                                    <div className="d-flex align-items-center justify-content-between w-100">
                                        <div className="d-flex align-items-center">
                                            <div className="mr-3">
                                                <img src="https://www.w3schools.com/howto/img_avatar2.png" className="rounded mx-auto d-block" width="90px" />
                                            </div>
                                            <div>
                                                <p className="p-0 m-0">Name: {customerDetails.name} |
                                                Email: {customerDetails.email} |
                                                Status: {customerDetails.active ? "Active" : "Inactive"}
                                                    <span className="d-block">Referred By: {customerDetails.referredByVendorName || "Nil"}</span></p>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <Button className="mr-3 btn btn-primary bg-warning px-2" onClick={() => this.changeConfirmationModal(true, customerDetails.active ? "Deactivate Customer" : "Activate Customer", `Are you sure you want to ${customerDetails.active ? "deactivate" : "activate"} this customer?`, "activate/deactivate")}>
                                                {
                                                    customerDetails.active ? "Deactivate" : "Activate"
                                                }
                                            </Button>
                                            <Button className="btn btn-primary bg-danger px-2 c-white" onClick={() => this.changeConfirmationModal(true, `Delete Customer`, `Are you sure you want to delete this customer? This action is irreversible.`, "delete")}>Delete</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <Row>
                                        <Col className="mt-3">
                                            <h6>Packages bought by customer</h6>
                                            <div className="d-flex mb-3">
                                                <input className="form-control pr-4 mr-3" type="search" placeholder="Search by name"
                                                    aria-label="Search" onChange={(e) => this.packageSearch("searchedPackageName", e.target.value)} />
                                                {/* <Label for="statusSelect">Status</Label> */}
                                                <Input type="select" name="select" id="statusSelect" onChange={(e) => this.packageSearch("searchedPackageStatus", e.target.value)}>
                                                    <option value="">Status All</option>
                                                    <option value="active">Active</option>
                                                    <option value="inactive">Inactive</option>
                                                </Input>
                                            </div>
                                            {
                                                customerPackages && customerPackages.length > 0 &&
                                                <div className="table-responsive radius-10 border scroll-2">
                                                    <table className="table mb-0 table-layout-fixed">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" width="10%">No</th>
                                                                <th scope="col">Package Name</th>
                                                                <th scope="col">Total Vouchers</th>
                                                                <th scope="col">Used Vouchers</th>
                                                                <th scope="col">Booking Time</th>
                                                                <th scope="col">Expiry</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                customerPackages.map((customerPackage, index) => {
                                                                    return (
                                                                        <tr key={customerPackage.id}>
                                                                            <td>{customerPackagesPerPage * (customerPackagesPageSelected - 1) + (index + 1)}</td>
                                                                            <td>{customerPackage.packageName}</td>
                                                                            <td>{customerPackage.defaultVoucherCount + customerPackage.addedVoucherCount}</td>
                                                                            <td>{customerPackage.usedVoucherCount}</td>
                                                                            <td>{moment(customerPackage.bookingTime).format("DD MMM YYYY hh:mm:ss A")}</td>
                                                                            <td>{moment(customerPackage.expiry).format("DD MMM YYYY hh:mm:ss A")}</td>
                                                                            <td>
                                                                                <Button className="btn btn-primary bg-gree px-2 " onClick={() => this.extendPackageModalShow(customerPackage.id, customerPackage.customerId)}>Extend</Button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                            {
                                                customerPackages && customerPackages.length == 0 &&
                                                <div>
                                                    This customer does not have a package history
                                                    </div>
                                            }
                                            <CommonPagination onPageChange={this.handlePageClickForPackages} pageCount={customerPackagesNoOfPages} selectedPage={customerPackagesPageSelected} />
                                        </Col>
                                        <Col className="mt-3">
                                            <h6>Vouchers used by customer</h6>
                                            <div className="d-flex mb-3">
                                                <input className="form-control pr-4 mr-3" type="search" placeholder="Search by name"
                                                    aria-label="Search" onChange={(e) => this.voucherSearch("searchedVendorName", e.target.value)} />
                                                {/* <Label for="statusSelectVoucher">Status</Label> */}
                                                <Input type="select" name="select" id="statusSelectVoucher" onChange={(e) => this.voucherSearch("searchedVoucherStatus", e.target.value)}>
                                                    <option value="">Status All</option>
                                                    <option value="scratched">Scratched</option>
                                                    <option value="redeemed">Redeemed</option>
                                                    <option value="expired">Expired</option>
                                                </Input>
                                            </div>
                                            {
                                                customerVouchers && customerVouchers.length > 0 &&
                                                <div className="table-responsive radius-10 border scroll-2">
                                                    <table className="table mb-0 table-layout-fixed">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" width="10%">No</th>
                                                                <th scope="col">Vendor</th>
                                                                <th scope="col">Offer</th>
                                                                <th scope="col">Status</th>
                                                                <th scope="col">Package</th>
                                                                <th scope="col">Expiry</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                customerVouchers.map((customerVoucher, index) => {
                                                                    return (
                                                                        <tr key={customerVoucher.id}>
                                                                            <td>{customerVouchersPerPage * (customerVouchersPageSelected - 1) + (index + 1)}</td>                                                                            <td>{customerVoucher.vendor}</td>
                                                                            <td>{customerVoucher.voucherOffer}</td>
                                                                            <td>{customerVoucher.status}</td>
                                                                            <td>{customerVoucher.packageName}</td>
                                                                            <td>{moment(customerVoucher.expiryTime).format("DD MMM YYYY hh:mm:ss A")}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                            {
                                                customerVouchers && customerVouchers.length == 0 &&
                                                <div>
                                                    This customer does not have a voucher history
                                                    </div>
                                            }
                                            <CommonPagination onPageChange={this.handlePageClickForVouchers} pageCount={customerVouchersNoOfPages} selectedPage={customerVouchersPageSelected} />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main >
        )
    }
}

export default CustomerDetails