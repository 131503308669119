import React, { Component } from 'react'
import nv from 'nvd3';
import d3 from 'd3';
import './index.css';

class BarChart extends Component {

    constructor(props) {
        super(props)
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.barChartData != this.props.barChartData) {
            this.renderChart()
        }
    }

    renderChart = () => {
        let height = 350;
        let width = 350;

        d3.selectAll("svg > *").remove();

        nv.addGraph(() => {
            let chart = nv.models.discreteBarChart()
                .height(350)
                .x((d) => { return d.label })
                .y(function (d) { return d.value })
                .forceY([0, this.props.graphXRange])
                .staggerLabels(true)
                // .tooltips(false) 
                .showValues(true)
                .duration(250)
                .color(['#7e86fd', '#00addd', '#00cadc', '#37e5d4', '#88eecd', '#0078ff'])
                .rotateLabels(-45)
                .margin({ top: 70 })//leave room for legend

            chart.yAxis.tickFormat(d3.format(',f'));

            let data = [
                {
                    values: this.props.barChartData,
                    key: "Packages purchased",
                }
            ]

            let svg = d3.select('#barChart')
                .datum(data)
            svg.transition().duration(500)
                .call(chart)
                .attr('width', width)
                .attr('height', height)

            let makeLegend = nv.models.legend()
                .key(function (d) { return d.label })
                .color(['#7e86fd', '#00addd', '#00cadc', '#37e5d4', '#88eecd', '#0078ff'])

            svg.append("g").attr("class", "legend")
                .datum(data[0].values)
                .transition().duration(500)
                .call(makeLegend)

            nv.utils.windowResize(chart.update);
            nv.utils.windowResize(makeLegend);

            return chart;
        });
    }

    render() {
        return (
            <svg id="barChart"></svg>
        )
    }
}

export default BarChart