import fetch from './handler'

export const getVouchers = (params) => {
    return fetch({
        method: 'GET',
        url: '/voucher',
        jsonData: true,
        params
    })
}

export const addVoucher = (body) => {
    return fetch({
        method: 'POST',
        url: '/voucher',
        jsonData: true,
        body
    })
}

export const editVoucher = (id, body) => {
    return fetch({
        method: 'PUT',
        url: `/voucher/${id}`,
        jsonData: true,
        body
    })
}

export const getVoucherReport = (params) => {
    return fetch({
        method: 'GET',
        url: `/voucher/report`,
        params,
    })
}

export const getLandingPageOffers = () => {
    return fetch({
        method: 'GET',
        url: `/voucher/offers`
    })
}

export const updateLandingPageOffers = (body) => {
    return fetch({
        method: 'POST',
        url: `/voucher/offers`,
        body
    })
}