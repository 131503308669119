import fetch from './handler'

export const getCategoryReport = (params) => {
    return fetch({
        url: '/report/category',
        method: 'GET',
        params
    })
}

export const getVendorReport = (params) => {
    return fetch({
        url: '/report/vendor',
        method: 'GET',
        params
    })
}

export const getPackageReport = (params) => {
    return fetch({
        url: '/report/package',
        method: 'GET',
        params
    })
}

export const getReferralReport = (params) => {
    return fetch({
        url: '/report/referral',
        method: 'GET',
        params
    })
}