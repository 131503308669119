import fetch from './handler'

export const getCategory = (params) => {
    return fetch({
        method: 'GET',
        url: '/category',
        params
    })
}

export const addCategory = (body) => {
    return fetch({
        method: 'POST',
        url: '/category',
        jsonData: true,
        body
    })
}

export const editCategory = (id, body) => {
    const url = `/category/${id}`
    return fetch({
        method: 'PUT',
        url: url,
        jsonData: true,
        body
    })
}

export const deleteCategory = (id) => {
    const url = `/category/${id}`
    return fetch({
        method: 'DELETE',
        url,
    })
}