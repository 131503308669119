import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class DeletePackage extends Component {
    render() {
        return (
            <Modal isOpen={this.props.modal}>
                <ModalHeader toggle={this.props.toggle}>Delete Package</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this package?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.props.submitDeletePackage}>Yes</Button>
                    <Button color="secondary" className="ml-2" onClick={this.props.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default DeletePackage