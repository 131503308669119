import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label } from 'reactstrap';
import { extendPackage } from '../../../../../api/package';

class ExtendPackage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            validity: 0,
            count: 0,
            alertMessage: "",
            alertColor: "",
            alertShow: false,
            buttonDisabled: false,
        }
    }

    changeValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    submit = () => {
        this.setState({
            buttonDisabled: true,
        }, () => {
            const body = {
                validity: this.state.validity,
                count: this.state.count,
                customerId: this.props && this.props.customerId
            }
            extendPackage(this.props.customerPackageId, body).then(() => {
                this.props.toggle()
                this.props.setAlert("Package extended successfully", "success")
                this.props.refreshPackageList()
            }).catch((error) => {
                this.props.setAlert(error.message, "danger")
            }).finally(() => {
                this.setState({
                    buttonDisabled: false
                })
            })
        })
    }

    render() {
        const { buttonDisabled } = this.state
        return (
            <Modal isOpen={this.props.isOpen}>
                <ModalHeader toggle={this.props.toggle}>Extend Package</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="validity">Validity</Label>
                        <Input name="validity" id="validity" type="number" placeholder="Enter the number of days by which the package should be extended" onChange={(e) => this.changeValue("validity", parseInt(e.target.value))} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="count">Count</Label>
                        <Input name="count" id="count" placeholder="Enter the number of vouchers which should be added to this particular voucher" onChange={(e) => this.changeValue("count", parseInt(e.target.value))} type="number" />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button className="mr-3" color="primary" onClick={this.submit} disabled={buttonDisabled}>Confirm</Button>{' '}
                    <Button color="secondary" onClick={this.props.toggle} disabled={buttonDisabled}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ExtendPackage;