import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { editUser } from '../../../../api/user';
import MultiSelect from 'react-multi-select-component';

class EditUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phoneNumber: '',
            role: '',
            status: '',
            permissionOptions: [],
            selectedPermissions: [],
            buttonDisabled: false,
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.modal != this.props.modal) {
            this.setState({
                name: this.props && this.props.user.name,
                phoneNumber: this.props && this.props.user.phoneNumber,
                role: this.props && this.props.user.role,
                status: this.props.user.active == true ? 'active' : 'inactive',
                prevStatus: this.props.user.active == true ? "active" : "inactive",
                selectedPermissions: [],
                buttonDisabled: false,
            }, () => {
                this.formatPermissions()
            })
        }
    }

    onInputChange = (value, key) => {
        this.setState({
            [key]: value
        })
    }

    formatPermissions = () => {
        const currentPermissions = this.props.user.permissions.split(',').map((perm) => parseInt(perm))
        let permissionOptions = []
        let selectedPermissions = []
        this.props.permissions.forEach(permission => {
            const perm = {
                label: permission.action,
                value: permission.id
            }
            permissionOptions.push(perm)
            if (currentPermissions.includes(permission.id)) {
                selectedPermissions.push(perm)
            }
        });
        this.setState({
            selectedPermissions,
            permissionOptions
        })
    }

    submitEditUser = () => {
        this.setState({
            buttonDisabled: true
        }, () => {
            const { name, phoneNumber, role, selectedPermissions, status, permissionOptions } = this.state
            let valid = true
            let alertMessage = ""
            if (!name) {
                valid = false
                alertMessage = 'Please enter a valid name'
            }
            if (!phoneNumber) {
                valid = false
                alertMessage = 'Please enter a valid phone number'
            }
            if (!role) {
                valid = false
                alertMessage = 'Please enter a valid role'
            }
            if ((role == 'user') && (selectedPermissions.length == 0)) {
                valid = false
                alertMessage = 'Please enter valid permission if role selected is user'
            }

            let permissions = []
            selectedPermissions.forEach(selectedPermission => {
                permissions.push(selectedPermission.value)
            });

            if (!valid) {
                this.props.showAlert(alertMessage, 'danger')
                this.setState({
                    buttonDisabled: false
                })
                return
            }
            let body = {
                name,
                phoneNumber,
                role
            }

            if (this.state.prevStatus != status) {
                body.status = status
            }

            if (role == 'user') {
                body.permissions = permissions.join()
            } else {
                let permissions = []
                permissionOptions.forEach(permission => {
                    permissions.push(permission.value)
                });
                body.permissions = permissions.join()
            }

            editUser(this.props.user.id, body).then((response) => {
                this.props.toggle()
                this.props.showAlert(response.message, 'success')
            }).catch((error) => {
                this.props.showAlert(error.message, 'danger')
            }).finally(() => {
                this.setState({
                    buttonDisabled: false
                })
            })
        })
    }

    selectPermissions = (selectedPermissions) => {
        this.setState({
            selectedPermissions,
        })
    }

    render() {
        const { name, phoneNumber, role, status, selectedPermissions, permissionOptions, buttonDisabled } = this.state
        return (
            <Modal isOpen={this.props.modal}>
                <ModalHeader toggle={this.props.toggle}>Edit User</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Input name="name" id="name" type="text" value={name} onChange={(e) => this.onInputChange(e.target.value, 'name')} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="phoneNumber">Phone</Label>
                            <Input name="phoneNumber" id="phoneNumber" type="text" value={phoneNumber} onChange={(e) => this.onInputChange(e.target.value, 'phoneNumber')} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="status">Status</Label>
                            <Input type="select" name="status" id="status" value={status} onChange={(e) => this.onInputChange(e.target.value, 'status')}>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="role">Role</Label>
                            <Input type="select" name="role" id="role" value={role} onChange={(e) => this.onInputChange(e.target.value, 'role')}>
                                <option disabled value="">Select role</option>
                                <option value="admin">Admin</option>
                                <option value="user">User</option>
                            </Input>
                        </FormGroup>
                        {
                            role == 'user' &&
                            <FormGroup>
                                <Label for="categories">Select Permissions</Label>
                                <MultiSelect
                                    options={permissionOptions}
                                    value={selectedPermissions}
                                    onChange={this.selectPermissions}
                                    labelledBy={"Select"}
                                />
                            </FormGroup>
                        }
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.submitEditUser} disabled={buttonDisabled}>Submit</Button>
                    <Button color="secondary" className="ml-2" onClick={this.props.toggle} disabled={buttonDisabled}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default EditUser