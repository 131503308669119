import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas'

class PreviewCategory extends Component {


    printPdf = () => {
        const input = document.getElementById('capture-category')

        let str = "category_"
        let fileName = ""
        if (this.props.fromDate == this.props.toDate) {
            fileName = str.concat(this.props.fromDate)
        } else {
            fileName = str.concat(this.props.fromDate + "_").concat(this.props.toDate)
        }

        html2canvas(input).then((canvas) => {
            let imgData = canvas.toDataURL('image/png');
            let margin = 2
            let imgWidth = 210 - margin * 2
            let pageHeight = 295
            let imgHeight = canvas.height * imgWidth / canvas.width;
            let heightLeft = imgHeight;
            let pdf = new jsPDF('p', 'mm');
            let position = 0;

            pdf.addImage(imgData, 'PNG', margin, position + 2, imgWidth, imgHeight - 2);
            heightLeft -= (pageHeight + 2)

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
                heightLeft -= pageHeight
            }

            pdf.save(fileName)
            this.props.toggle()
        })
    }


    render() {
        return (
            <Modal isOpen={this.props.modal} className="modal-lg">
                <ModalHeader toggle={this.props.toggle}>Preview</ModalHeader>
                <ModalBody>
                    <div id="capture-category">
                        {
                            this.props && this.props.fromDate == this.props.toDate &&
                            <h6 className="p-0 m-0 mb-3">Date of the report : {this.props.fromDate}
                            </h6>
                        }
                        {
                            this.props && this.props.fromDate != this.props.toDate &&
                            <h6 className="p-0 m-0 mb-3">Date range of the report : {this.props.fromDate} to {this.props.toDate}
                            </h6>
                        }
                        {
                            this.props && this.props.categories && this.props.categories.length > 0 &&
                            <table className="table mb-0 table-layout-fixed" >
                                <thead>
                                    <tr>
                                        <th scope="col" width="10%">No</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Voucher Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.categories.map((category, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{category.name}</td>
                                                    <td>{category.count}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        }
                        {
                            this.props && !this.props.categories &&
                            <h6 className="mt-3">No vouchers are redeemed</h6>
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" className={this.props && this.props.categories ? "mr-2" : "mr-2 disabled"} onClick={this.printPdf}>Proceed</Button>
                    <Button color="secondary" onClick={this.props.toggle}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default PreviewCategory