import fetch from './handler'

export const getUsers = (params) => {
    return fetch({
        method: 'GET',
        url: '/user',
        params
    })
}

export const getAllPermissions = () => {
    return fetch({
        method: 'GET',
        url: '/permission'
    })
}

export const addUser = (body) => {
    return fetch({
        method: 'POST',
        url: '/user',
        jsonData: true,
        body
    })
}

export const editUser = (id, body) => {
    const url = `/user/${id}`
    return fetch({
        method: 'PUT',
        url,
        jsonData: true,
        body
    })
}

export const deleteUser = (id) => {
    const url = `/user/${id}`
    return fetch({
        method: 'DELETE',
        url
    })
}

export const getUserDetails = () => {
    return fetch({
        method: 'GET',
        url: '/user/detail'
    })
}

export const userLogout = () => {
    return fetch({
        method: 'POST',
        url: '/user/logout'
    })
}

export const resetPassword = (id, body) => {
    return fetch({
        url: `/user/${id}/password/reset`,
        method: 'PUT',
        body,
        jsonData: true
    })
}