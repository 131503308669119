import React, { Component } from 'react'
import randomstring from 'randomstring';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { addVendor } from '../../../../api/vendor';
import { getCognitoToken } from '../../../../api/aws';
import { setBucket } from '../../../../aws/awsupload';
import MultiSelect from 'react-multi-select-component';
import MapComponent from '../Map';
import Geocode from "react-geocode";
import { GOOGLE_API_KEY } from '../../../../config'

Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.setLanguage("en");
class AddVendor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phoneNumber: '',
            imageURL: '',
            imageFile: '',
            imageFileName: '',
            redemptionCode: '',
            vendorCode: '',
            latitude: 0.0,
            longitude: 0.0,
            location: '',
            category: [],
            cognitoToken: '',
            identityID: '',
            categoryOptions: [],
            selectedCategories: [],
            alertColor: '',
            buttonDisabled: false,
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.modal != this.props.modal) {
            this.stateInit()
        }
    }

    stateInit = () => {
        this.setState({
            name: '',
            email: '',
            phoneNumber: '',
            imageURL: '',
            imageFile: '',
            imageFileName: '',
            redemptionCode: '',
            vendorCode: '',
            latitude: 0.0,
            longitude: 0.0,
            location: '',
            category: [],
            cognitoToken: '',
            identityID: '',
            categoryOptions: [],
            selectedCategories: [],
            buttonDisabled: false
        })
        this.prepareCategories()
    }

    prepareCategories = () => {
        let categoryOptions = []
        this.props.categories.forEach(category => {
            categoryOptions.push({
                label: category.name,
                value: category.id
            })
        });
        this.setState({
            categoryOptions,
        })
    }

    changeCoordinates = (latitude, longitude) => {
        this.setState({
            latitude,
            longitude
        })
    }

    onInputChange = (value, key) => {
        this.setState({
            [key]: value
        })
    }

    handleImageChange = (event) => {
        this.setState({
            imageURL: URL.createObjectURL(event.target.files[0]),
            imageFile: event.target.files[0],
            imageFileName: randomstring.generate() + "." + event.target.files[0].type.split('/')[1]
        })
    }

    selectCategories = (selectedCategories) => {
        this.setState({
            selectedCategories,
        })
    }

    changeLocation = (location) => {
        this.setState(
            {
                location: location,
            },
            () => {
                Geocode.fromAddress(location).then(
                    (response) => {
                        const { lat, lng } =
                            response.results[0].geometry.location;
                        this.setState({
                            latitude: lat,
                            longitude: lng,
                        });
                    },
                    (error) => {
                        console.error(error);
                    }
                );
            }
        );
    };

    submitAddVendor = () => {
        this.setState({
            buttonDisabled: true
        }, () => {
            const { name, email, phoneNumber, imageFileName, redemptionCode, vendorCode, latitude, longitude, selectedCategories, imageURL, location } = this.state
            let valid = true
            let alertMessage = ""
            if (!name) {
                valid = false
                alertMessage = "Please enter a valid vendor name"
            }
            if (!email || !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
                valid = false
                alertMessage = "Please enter a valid vendor email"
            }
            // if (!phoneNumber || !/^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/.test(phoneNumber)) {
            //     valid = false
            //     alertMessage = "Please enter a valid phone number"
            // }
            if (!phoneNumber || phoneNumber.length < 8 || phoneNumber.length > 10) {
                valid = false
                alertMessage = "Please enter a valid phone number"
            }
            if (!redemptionCode) {
                valid = false
                alertMessage = "Please enter a valid redemption code"
            }
            if (!vendorCode) {
                valid = false
                alertMessage = "Please enter a valid vendor code"
            }
            if (selectedCategories.length == 0) {
                valid = false
                alertMessage = "Please select atleast one category"
            }
            if (!location) {
                valid = false
                alertMessage = "Please enter a valid location"
            }
            if (!latitude || parseFloat(latitude, 10) < -90 || parseFloat(latitude, 10) > 90) {
                valid = false
                alertMessage = "Please enter a valid latitude"
            }
            if (!longitude || parseFloat(longitude, 10) < -180 || parseFloat(longitude, 10) > 180) {
                valid = false
                alertMessage = "Please enter a valid longitude"
            }
            if (!valid) {
                this.props.showAlert("danger", alertMessage)
                this.setState({
                    buttonDisabled: false
                })
                return;
            }
            let category = []
            selectedCategories.forEach(selectedCategory => {
                category.push(selectedCategory.value)
            });
            let body = {
                name,
                email,
                phone: phoneNumber,
                redemptionCode,
                vendorCode,
                latitude: parseFloat(latitude, 10),
                longitude: parseFloat(longitude, 10),
                category,
                location
            }
            let bucket = {}
            if (imageURL != "") {
                body.imageURL = "vendor/" + imageFileName

                let params = {}
                params.identityId = ""
                getCognitoToken(params).then((response) => {
                    this.setState({
                        cognitoToken: response.data.cognitoToken,
                        identityID: response.data.identityId
                    }, () => {
                        // set S3 bucket after retreiving token successfully
                        bucket = setBucket(this.state.cognitoToken)

                        let file = this.state.imageFile
                        let fileKey = `vendor/${this.state.imageFileName}`;
                        let params = {
                            Key: fileKey,
                            ContentType: file.type,
                            Body: file,
                            ACL: 'public-read',

                        };
                        bucket.putObject(params).on('httpUploadProgress', (progress) => {
                            // console.log("progress", progress.loaded * 100 / progress.total)
                            if (parseInt((progress.loaded * 100) / progress.total) === 100) {
                                this.submitAddVendorAPI(body)
                            }
                        }).send((err) => {
                            if (err) {
                                console.log("Error while uploading video to s3 bucket in add category ", err)
                                this.props.showAlert("danger", "An unexpected error occured")
                                this.setState({
                                    buttonDisabled: false
                                })
                            }
                        })
                    })
                }).catch((error) => {
                    console.log("Error while getting cognito token ", error)
                    this.props.showAlert("danger", "An unexpected error occured")
                    this.setState({
                        buttonDisabled: false
                    })
                })
            } else {
                this.submitAddVendorAPI(body)
            }
        })
    }

    submitAddVendorAPI = (body) => {
        addVendor(body).then((response) => {
            if (response) {
                this.props.toggle()
                this.props.showAlert("success", response.message)
            }
        }).catch((error) => {
            this.props.showAlert("danger", error.message || "An unexpected error occured")
        }).finally(() => {
            this.setState({
                buttonDisabled: false
            })
        })
    }

    render() {
        const { name, email, phoneNumber, imageURL, redemptionCode, vendorCode, latitude, longitude, categoryOptions, selectedCategories, location, buttonDisabled } = this.state
        return (
            <Modal size="lg" isOpen={this.props.modal}>
                <ModalHeader toggle={this.props.toggle}>Add Vendor</ModalHeader>
                <ModalBody>
                    <Form className="row">
                        <FormGroup className="col-6">
                            <Label for="name">Name</Label>
                            <Input name="name" value={name} type="text" placeholder="Enter vendor" onChange={(e) => this.onInputChange(e.target.value, 'name')} />
                        </FormGroup>
                        <FormGroup className="col-6">
                            <Label for="email">Email</Label>
                            <Input name="email" value={email} type="email" placeholder="Enter email" onChange={(e) => this.onInputChange(e.target.value, 'email')} />
                        </FormGroup>
                        <FormGroup className="col-6">
                            <Label for="phone">Phone number</Label>
                            <Input name="phone" value={phoneNumber} type="text" placeholder="Enter phone" onChange={(e) => this.onInputChange(e.target.value, 'phoneNumber')} />
                        </FormGroup>
                        <FormGroup className="col-6">
                            <Label for="categories">Select Categories</Label>
                            <MultiSelect
                                options={categoryOptions}
                                value={selectedCategories}
                                onChange={this.selectCategories}
                                labelledBy={"Select"}
                            />
                        </FormGroup>
                        <FormGroup className="col-6">
                            <Label for="redemption">Redemption code</Label>
                            <Input name="redemption" value={redemptionCode} type="text" placeholder="Enter redemption code" onChange={(e) => this.onInputChange(e.target.value, 'redemptionCode')} />
                        </FormGroup>
                        <FormGroup className="col-6">
                            <Label for="vendor">Vendor code</Label>
                            <Input name="vendor" value={vendorCode} type="email" placeholder="Enter vendor code" onChange={(e) => this.onInputChange(e.target.value, 'vendorCode')} />
                        </FormGroup>
                        <FormGroup className="col-12">
                            <Label for="location">Location</Label>
                            <Input name="location" value={location} type="text" placeholder="Enter location" onChange={(e) => this.changeLocation(e.target.value)} />
                        </FormGroup>
                        <FormGroup className="col-6">
                            <label>Coordinates</label>
                            <MapComponent isMarkerShown onMarkerClick={(params) => this.changeCoordinates(params.latLng.lat(), params.latLng.lng())} latitude={latitude} longitude={longitude} />
                            <small className="d-flex mt-3">Coordinates lat <span className="c-bold-blue px-2">{latitude}</span> long <span className="c-bold-blue px-2">{longitude}</span></small>
                        </FormGroup>
                        <FormGroup className="col-6">
                            <Label for="image">Image</Label>
                            <span className="card uploadImgwrap2 d-flex align-items-center justify-content-center position-relative">
                                <Input name="image" type="file" accept="image/*" placeholder="Select image" onChange={this.handleImageChange} className="addModalChooseImage h-100" />
                                {
                                    imageURL &&
                                    <img className="mx-auto py-3" src={imageURL} alt="" />
                                }
                            </span>
                            <small className="d-flex mt-3">Please click on the above box to add image</small>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.submitAddVendor} disabled={buttonDisabled}>Submit</Button>
                    <Button color="secondary" className="ml-2" onClick={this.props.toggle} disabled={buttonDisabled}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default AddVendor