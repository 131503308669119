import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getLandingPageOffers, getVouchers, updateLandingPageOffers } from '../../../../api/voucher';
import AsyncSelect from 'react-select/async';
import './index.css';

class LandingPageOffers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            offers: [],
            vouchers: [],
        }
    }

    componentDidMount() {
        this.listLandingPageOffers()
    }

    componentDidUpdate(prevProps) {
        if (this.props.show != prevProps.show && this.props.show) {
            this.listLandingPageOffers()
        }
    }

    listLandingPageOffers = () => {
        getLandingPageOffers().then((response) => {
            let offers = []
            for (let offer of response.data.offers) {
                offers.push({
                    label: `${offer.offer} at ${offer.vendorName}`,
                    value: offer.voucherId
                })
            }
            this.setState({
                offers,
            })
        })
    }

    loadOptions = (searchKey) => {
        return new Promise(resolve => {
            getVouchers({
                searchKey,
                status: "active"
            }).then((response) => {
                let vouchers = []
                for (let voucher of response.data.vouchers) {
                    vouchers.push({
                        label: `${voucher.offer} at ${voucher.vendor}`,
                        value: voucher.id
                    })
                }
                resolve(vouchers)
            })
        })
    }

    voucherSelected = (offers) => {
        this.setState({
            offers,
        })
    }

    submit = () => {
        let vouchers = []
        for (let offer of this.state.offers) {
            vouchers.push(offer.value)
        }
        updateLandingPageOffers({
            vouchers
        }).then(() => {
            this.props.toggle()
        })
    }

    render() {
        const { offers } = this.state
        return (
            <Modal isOpen={this.props.show} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>Landing Page Offers</ModalHeader>
                <ModalBody className="offers-modal">
                    <p>Choose the offers that should be advertised on the landing page</p>
                    <AsyncSelect
                        isMulti
                        value={offers}
                        loadOptions={this.loadOptions}
                        onChange={this.voucherSelected} />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.submit}>Submit</Button>
                    <Button color="secondary" onClick={this.props.toggle} className="ml-2">Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default LandingPageOffers;