import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import App from './components/App'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/stylesheets/style.scss'

ReactDOM.render(
    <BrowserRouter>
    <App/>
    </BrowserRouter>
    , document.getElementById('app')
)