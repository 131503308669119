import React, { Component, Fragment } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import moment from 'moment';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import Category from './Category';
import Vendor from './Vendor';
import Package from './Package';
import CustomerList from './CustomerList';
import Referral from './Referral'

class Report extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            categoryVouchers: [],
            vendorVouchers: [],
            packagesCount: [],
            calenderDropdown: false,
            numberOfMonths: 2,
            from: undefined,
            to: undefined,
            range: undefined,
            today: false
        }
    }

    componentDidMount = () => {
        this.setState({
            fromDate: moment().format("YYYY-MM-DD"),
            toDate: moment().format("YYYY-MM-DD"),
            today: true
        })
    }

    toggle = (tab) => {
        this.setState({
            activeTab: tab
        })
    }

    showCalenderDropdown = () => {
        this.setState(prevState => ({
            calenderDropdown: !prevState.calenderDropdown,
        }))
    }

    handleDayClick = (day) => {
        const range = DateUtils.addDayToRange(day, this.state);
        this.setState(range, () => {
            this.setState({
                today: false
            })
        })
    }

    reportDetails = (event) => {
        event.preventDefault()
        this.setState({
            fromDate: moment(this.state.from).format('YYYY-MM-DD'),
            toDate: moment(this.state.to).format('YYYY-MM-DD')
        }, () => {
            this.showCalenderDropdown()
            this.setState({
                from: undefined,
                to: undefined,
                range: undefined
            })
        })
    }

    render() {

        const { fromDate, toDate, calenderDropdown, numberOfMonths, from, to, today } = this.state
        const modifiers = { start: from, end: to };
        return (
            <main id="main">
                <section className="content-wrapper">
                    <div className="box box-shadow radius-10 tab-style1 h-100">
                        <div className="box-header px-3 pt-2 pb-3">
                            <div className="mt-3 bg-solitude radius-10 y-center pl-2 py-3 tab-report position-relative">
                                {
                                    this.state.activeTab != '5' &&
                                    <Fragment>
                                        <div className="position-absolute report-calender d-flex align-items-center justify-content-end">
                                            {
                                                today &&
                                                <p className="mb-0 mr-3"><strong>{fromDate}</strong></p>
                                            }
                                            {
                                                !today &&
                                                <p className="mb-0 mr-3"><strong>{fromDate} - {toDate}</strong></p>
                                            }

                                            <button onClick={this.showCalenderDropdown} className="btn h-100 input-group-text calender-btn pointer"><span className="fi flaticon-calendar"></span></button>
                                            <div className={calenderDropdown ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="dropdownMenuButton">
                                                <DayPicker
                                                    numberOfMonths={numberOfMonths}
                                                    selectedDays={[from, { from, to }]}
                                                    modifiers={modifiers}
                                                    onDayClick={this.handleDayClick}
                                                />
                                                <button onClick={(e) => this.reportDetails(e)} className={(from && to) ? "btn btn-primary ml-3" : "btn btn-primary ml-3 disabled"}>See result</button>
                                            </div>
                                        </div>
                                    </Fragment>
                                }
                                <div className="col">
                                    <Nav className="nav-ul">
                                        <NavItem>
                                            <NavLink
                                                className={this.state.activeTab === '1' ? 'text-uppercase py-1 pointer activeNav' : 'text-uppercase py-1 pointer'}
                                                onClick={() => { this.toggle('1'); }}>
                                                Category
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={this.state.activeTab === '2' ? 'text-uppercase py-1 pointer activeNav' : 'text-uppercase py-1 pointer'}
                                                onClick={() => { this.toggle('2'); }}>
                                                Vendor
                                        </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={this.state.activeTab === '3' ? 'text-uppercase py-1 pointer activeNav' : 'text-uppercase py-1 pointer'}
                                                onClick={() => { this.toggle('3'); }}>
                                                Package
                                        </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={this.state.activeTab === '4' ? 'text-uppercase py-1 pointer activeNav' : 'text-uppercase py-1 pointer'}
                                                onClick={() => { this.toggle('4'); }}>
                                                Referrals
                                        </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={this.state.activeTab === '5' ? 'text-uppercase py-1 pointer activeNav' : 'text-uppercase py-1 pointer'}
                                                onClick={() => { this.toggle('5'); }}>
                                                Customer
                                        </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </div>
                        </div>
                        <div className="box-body px-3 pt-0 position-relative">
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <Category today={today} fromDate={fromDate} toDate={toDate} />
                                </TabPane>
                                <TabPane tabId="2">
                                    <Vendor today={today} fromDate={fromDate} toDate={toDate} />
                                </TabPane>
                                <TabPane tabId="3">
                                    <Package today={today} fromDate={fromDate} toDate={toDate} />
                                </TabPane>
                                <TabPane tabId="4">
                                    <Referral fromDate={fromDate} toDate={toDate} />
                                </TabPane>
                                <TabPane tabId="5" className="with-nav-pills">
                                    {
                                        this.state.activeTab == 5 &&
                                        <CustomerList />
                                    }
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}

export default Report