import React, { Component } from 'react'
import CommonPagination from '../../../commonComponents/CommonPagination';
import { getVendorReport } from '../../../../api/report';
import PreviewVendor from './Preview';
import moment from 'moment'


class Vendor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vendors: [],
            total: 0,
            noOfPages: 0,
            perPage: 0,
            selectedPage: 1,
            previewModal: false
        }
    }

    componentDidUpdate = (prevProps) => {
        if ((this.props.fromDate != prevProps.fromDate) || (this.props.toDate != prevProps.toDate)) {
            this.getVendor(this.props.fromDate, this.props.toDate, 1, true)
        }
    }

    getVendor = (fromDate, toDate, page, limit) => {
        let params = {
            fromDate,
            toDate: moment(toDate).add(1, 'd').format("YYYY-MM-DD"),
            page,
            limit
        }
        getVendorReport(params).then((response) => {
            this.setState({
                vendors: response.data.vendor,
                total: response.data.total,
                noOfPages: response.data.noOfPages,
                perPage: response.data.perPage,
                selectedPage: page
            })
        })
    }

    handlePageClick = (page) => {
        this.getVendor(this.props.fromDate, this.props.toDate, page.selected + 1, true)
    }

    previewPdf = () => {
        this.getVendor(this.props.fromDate, this.props.toDate, 1)
        this.togglePreviewModal()
    }

    togglePreviewModal = () => {
        this.setState(prevState => ({
            previewModal: !prevState.previewModal
        }))
    }

    render() {

        const { vendors, noOfPages, perPage, selectedPage, previewModal } = this.state

        return (
            <div className="card">
                <div className="card-header p-3 d-flex justify-content-between align-items-center">
                    <h5 className="m-0">Vendor Report</h5>
                    <button className={vendors ? "btn btn-primary bg-rust ml-4" : "btn btn-primary bg-rust ml-4 disabled"} onClick={this.previewPdf}>Download PDF</button>
                </div>
                <div className="card-body p-0">
                    {
                        vendors && vendors.length > 0 &&
                        <table className="table mb-0 table-layout-fixed" >
                            <thead>
                                <tr>
                                    <th scope="col" width="10%">No</th>
                                    <th scope="col">Vendor</th>
                                    <th scope="col">Location</th>
                                    <th scope="col">Voucher Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    vendors.map((vendor, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{perPage * (selectedPage - 1) + (index + 1)}</td>
                                                <td>{vendor.name}</td>
                                                <td>{vendor.location}</td>
                                                <td>{vendor.count}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    }
                    {
                        !vendors &&
                        <h6 className="text-center mt-3">There are no redemption associated with any vendors on this day/selected date range.</h6>
                    }
                    <CommonPagination onPageChange={this.handlePageClick} pageCount={noOfPages} selectedPage={selectedPage} />
                </div>
                <PreviewVendor vendors={vendors} modal={previewModal} toggle={this.togglePreviewModal} fromDate={this.props.fromDate} toDate={this.props.toDate} />
            </div>
        )
    }
}

export default Vendor