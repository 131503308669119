import AWS from 'aws-sdk'
import { AWS_ROLE_ARN, BUCKET_NAME, REGION } from '../config'

export const setBucket = (token) => {
    let roleArn = AWS_ROLE_ARN;
    let bucketName = BUCKET_NAME;
    AWS.config.region = REGION;
    AWS.config.credentials = new AWS.WebIdentityCredentials({
        RoleArn: roleArn,
        WebIdentityToken: token
    });

    let bucket = new AWS.S3({
        params: {
            Bucket: bucketName
        }
    })

    return bucket
}