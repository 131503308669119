import React, { Component } from 'react'
import CommonPagination from '../../../commonComponents/CommonPagination';
import { getCustomers } from '../../../../api/customer';
import { getVoucherReport } from '../../../../api/voucher';
import { getPackageReport } from '../../../../api/package';
import PreviewCustomer from './Preview';


class CustomerList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customers: [],
            total: 0,
            noOfPages: 0,
            perPage: 0,
            selectedPage: 1,
            customerVouchers: [],
            customerPackages: [],
            previewModal: false,
            previewCustomer: {},
            searchName: ''
        }
    }

    componentDidMount = () => {
        this.getCustomerList(1)
    }

    getCustomerList = (page, name) => {
        let params = {
            page,
            name
        }
        getCustomers(params).then((response) => {
            this.setState({
                customers: response.data.customers,
                total: response.data.total,
                noOfPages: response.data.noOfPages,
                perPage: response.data.perPage,
                selectedPage: page
            })
        })
    }

    handlePageClick = (page) => {
        this.getCustomerList(page.selected + 1)
    }

    searchCustomer = (name) => {
        this.setState({
            searchName: name
        }, () => {
            this.getCustomerList(1, this.state.searchName)
        })
    }

    getCustomerDetails = (customer) => {

        this.setState({
            previewCustomer: customer
        })

        let params = {}
        params.customerId = customer.id
        getPackageReport(params).then((response) => {
            this.setState({
                customerPackages: response.data.packages
            }, () => {
                getVoucherReport(params).then((response) => {
                    this.setState({
                        customerVouchers: response.data.vouchers
                    }, () => {
                        this.togglePreviewModal()
                    })
                })
            })
        })
    }

    togglePreviewModal = () => {
        this.setState(prevState => ({
            previewModal: !prevState.previewModal
        }))
    }

    render() {
        const { customers, noOfPages, perPage, selectedPage, previewModal, previewCustomer, customerVouchers, customerPackages, searchName } = this.state

        return (
            <div className="card">
                <div className="card-header p-3 d-flex justify-content-between align-items-center">
                    <h5 className="m-0">Customer Report</h5>
                    <div className="d-flex">
                        <input className="form-control" type="search" placeholder="Search by name" value={searchName}
                            aria-label="Search" onChange={(e) => this.searchCustomer(e.target.value)} />
                    </div>
                </div>
                <div className="card-body p-0">
                    {
                        customers && customers.length > 0 &&
                        <table className="table mb-0 table-layout-fixed">
                            <thead>
                                <tr>
                                    <th scope="col" width="10%">No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    customers.map((customer, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{perPage * (selectedPage - 1) + (index + 1)}</td>
                                                <td>{customer.name}</td>
                                                <td>{customer.active ? "Active" : "Inactive"}</td>
                                                <td><button onClick={() => this.getCustomerDetails(customer)} className="btn btn-primary bg-rust ml-4">Download</button></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    }
                    {
                        !customers &&
                        <h6 className="text-center mt-3">No customers have registered.</h6>
                    }
                    <CommonPagination onPageChange={this.handlePageClick} pageCount={noOfPages} selectedPage={selectedPage} />
                </div>
                <PreviewCustomer modal={previewModal} toggle={this.togglePreviewModal} customer={previewCustomer} vouchers={customerVouchers} packages={customerPackages} />
            </div >
        )
    }
}

export default CustomerList