import React, { Component, Fragment } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas'
import moment from 'moment'

class PreviewCustomer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customerDetails: {}
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.modal != prevProps.modal) {
            this.setState({
                customerDetails: this.props.customer
            })
        }
    }

    printPdf = () => {
        const input = document.getElementById('capture-customer')

        let str = "customer_"
        let fileName = str.concat(this.state.customerDetails.name)

        html2canvas(input).then((canvas) => {
            let imgData = canvas.toDataURL('image/png');
            let margin = 2
            let imgWidth = 210 - margin * 2
            let pageHeight = 295
            let imgHeight = canvas.height * imgWidth / canvas.width;
            let heightLeft = imgHeight;
            let pdf = new jsPDF('p', 'mm');
            let position = 0;

            pdf.addImage(imgData, 'PNG', margin, position + 2, imgWidth, imgHeight - 8);
            heightLeft -= (pageHeight + 8)

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
                heightLeft -= pageHeight
            }

            pdf.save(fileName)
            this.props.toggle()
        })
    }



    render() {

        const { customerDetails } = this.state

        return (
            <Modal isOpen={this.props.modal} className="modal-lg">
                <ModalHeader toggle={this.props.toggle}>Preview</ModalHeader>
                <ModalBody>

                    <div id="capture-customer">
                        <p className="p-0 m-0">Name: {customerDetails.name} |
                                                Email: {customerDetails.email} |
                                                Status: {customerDetails.active ? "Active" : "Inactive"}
                        </p>
                        {
                            this.props && this.props.packages && this.props.packages.length > 0 &&
                            <Fragment>
                                <h6 className="mt-3">Packages bought by customer</h6>
                                <div className="table-responsive radius-10 border scroll-2">
                                    <table className="table mb-0 table-layout-fixed">
                                        <thead>
                                            <tr>
                                                <th scope="col" width="10%">No</th>
                                                <th scope="col">Package Name</th>
                                                <th scope="col">Total Vouchers</th>
                                                <th scope="col">Used Vouchers</th>
                                                <th scope="col">Booking Time</th>
                                                <th scope="col">Expiry</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.props.packages.map((customerPackage, index) => {
                                                    return (
                                                        <tr key={customerPackage.id}>
                                                            <td>{index + 1}</td>
                                                            <td>{customerPackage.packageName}</td>
                                                            <td>{customerPackage.defaultVoucherCount + customerPackage.addedVoucherCount}</td>
                                                            <td>{customerPackage.usedVoucherCount}</td>
                                                            <td>{moment(customerPackage.bookingTime).format("DD MMM YYYY hh:mm:ss A")}</td>
                                                            <td>{moment(customerPackage.expiry).format("DD MMM YYYY hh:mm:ss A")}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Fragment>
                        }
                        {
                            this.props && this.props.packages && this.props.packages.length == 0 &&
                            <h6 className="mt-3">This customer have not purchased any packages and hence no vouchers redeemed.</h6>
                        }
                        {
                            this.props && this.props.vouchers && this.props.vouchers.length > 0 &&
                            <Fragment>
                                <h6 className="mt-3">Vouchers used by customer</h6>
                                <div className="table-responsive radius-10 border scroll-2">
                                    <table className="table mb-0 table-layout-fixed">
                                        <thead>
                                            <tr>
                                                <th scope="col" width="10%">No</th>
                                                <th scope="col">Vendor</th>
                                                <th scope="col">Offer</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Package</th>
                                                <th scope="col">Expiry</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.props.vouchers.map((customerVoucher, index) => {
                                                    return (
                                                        <tr key={customerVoucher.id}>
                                                            <td>{index + 1}</td>
                                                            <td>{customerVoucher.vendor}</td>
                                                            <td>{customerVoucher.voucherOffer}</td>
                                                            <td>{customerVoucher.status}</td>
                                                            <td>{customerVoucher.packageName}</td>
                                                            <td>{moment(customerVoucher.expiryTime).format("DD MMM YYYY hh:mm:ss A")}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Fragment>
                        }
                    </div>


                </ModalBody>
                <ModalFooter>
                    <Button color="primary" className="mr-2" disabled={((this.props.packages.length == 0) && (this.props.vouchers.length == 0))} onClick={this.printPdf}>Proceed</Button>
                    <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default PreviewCustomer