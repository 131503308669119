import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { addUser } from '../../../../api/user';
import MultiSelect from 'react-multi-select-component';

class AddUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            newPassword: '',
            confirmPassword: '',
            phoneNumber: '',
            role: '',
            permissionOptions: [],
            selectedPermissions: [],
            buttonDisabled: false,
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.modal != this.props.modal) {
            this.setState({
                name: '',
                email: '',
                newPassword: '',
                confirmPassword: '',
                phoneNumber: '',
                role: '',
                permissionOptions: [],
                selectedPermissions: [],
                buttonDisabled: false,
            })
            this.formatPermissions()
        }
    }

    formatPermissions = () => {
        let permissionOptions = [];
        this.props.permissions.forEach(permission => {
            permissionOptions.push({
                label: permission.action,
                value: permission.id
            })
        });
        this.setState({
            permissionOptions
        })
    }

    onInputChange = (value, key) => {
        this.setState({
            [key]: value
        })
    }

    selectPermissions = (selectedPermissions) => {
        this.setState({
            selectedPermissions,
        })
    }

    submitAddUser = () => {
        this.setState({
            buttonDisabled: true
        }, () => {
            const { name, email, newPassword, confirmPassword, phoneNumber, role, selectedPermissions, permissionOptions } = this.state
            let valid = true
            let alertMessage = ""

            if (!name) {
                valid = false
                alertMessage = 'Please enter a valid name'
            }
            if (!email || !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
                valid = false
                alertMessage = 'Please enter a valid email'
            }
            if (!newPassword) {
                valid = false
                alertMessage = 'Please enter valid password'
            }
            if (newPassword != confirmPassword) {
                valid = false
                alertMessage = "The passwords doesn't match"
            }
            if (!phoneNumber) {
                valid = false
                alertMessage = 'Please enter a valid phone number'
            }
            if (!role) {
                valid = false
                alertMessage = 'Please enter valid role'
            }
            if ((role == 'user') && (selectedPermissions.length == 0)) {
                valid = false
                alertMessage = 'Please select a set of permissions'
            }

            if (!valid) {
                this.props.showAlert(alertMessage, 'danger')
                this.setState({
                    buttonDisabled: false
                })
                return
            }

            let permissions = []
            selectedPermissions.forEach(selectedPermission => {
                permissions.push(selectedPermission.value)
            });

            let body = {
                name,
                email,
                phoneNumber,
                role,
                password: confirmPassword
            }

            if (role == 'user') {
                body.permissions = permissions.join()
            } else {
                let permissions = []
                permissionOptions.forEach(permission => {
                    permissions.push(permission.value)
                });
                body.permissions = permissions.join()
            }

            addUser(body).then((response) => {
                this.props.toggle()
                this.props.showAlert(response.message, 'success')
            }).catch((error) => {
                this.props.showAlert(error.message, 'danger')
            }).finally(() => {
                this.setState({
                    buttonDisabled: false
                })
            })
        })
    }

    render() {
        const { name, email, newPassword, confirmPassword, phoneNumber, role, selectedPermissions, permissionOptions, buttonDisabled } = this.state
        return (
            <Modal isOpen={this.props.modal}>
                <ModalHeader toggle={this.props.toggle}>Add User</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Input name="name" id="name" type="text" value={name} onChange={(e) => this.onInputChange(e.target.value, 'name')} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input name="email" id="email" type="email" value={email} onChange={(e) => this.onInputChange(e.target.value, 'email')} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="newPassword">New Password</Label>
                            <Input name="newPassword" id="newPassword" type="password" value={newPassword} onChange={(e) => this.onInputChange(e.target.value, 'newPassword')} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="confirmPassword">Confirm Password</Label>
                            <Input name="confirmPassword" id="confirmPassword" type="password" value={confirmPassword} onChange={(e) => this.onInputChange(e.target.value, 'confirmPassword')} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="phoneNumber">Phone</Label>
                            <Input name="phoneNumber" id="phoneNumber" type="text" value={phoneNumber} onChange={(e) => this.onInputChange(e.target.value, 'phoneNumber')} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="role">Role</Label>
                            <Input type="select" name="role" id="role" value={role} onChange={(e) => this.onInputChange(e.target.value, 'role')}>
                                <option disabled value="">Select role</option>
                                <option value="admin">Admin</option>
                                <option value="user">User</option>
                            </Input>
                        </FormGroup>
                        {
                            role == 'user' &&
                            <FormGroup>
                                <Label for="categories">Select Permissions</Label>
                                <MultiSelect
                                    options={permissionOptions}
                                    value={selectedPermissions}
                                    onChange={this.selectPermissions}
                                    labelledBy={"Select"}
                                />
                            </FormGroup>
                        }
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.submitAddUser} disabled={buttonDisabled}>Submit</Button>
                    <Button color="secondary" className="ml-2" onClick={this.props.toggle} disabled={buttonDisabled}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default AddUser