import React from 'react';
import { Alert } from 'reactstrap';

const CommonAlert = (props) => {
    return (
        props.show &&
        <div className="xy-center-alert">
            <Alert color={props.color} isOpen={props.show} className="m-0">
                {props.message}
            </Alert>
        </div>
    )
}
export default CommonAlert
