import React, { Component } from 'react'
import moment from 'moment'
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { userLogout } from '../../../api/user'

class Navbar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            time: '00:00 AM',
            today: '',
            showUserDropdown: false,
            calenderDropdown: false,
            calenderStatus: '',
            numberOfMonths: 2,
            from: undefined,
            to: undefined,
            range: undefined,
        }
    }

    componentDidMount = () => {
        this.intervalID = window.setInterval(function () {
            this.setTime();
        }.bind(this), 1000);
        this.setDate();
        this.changeCalender('daily')
    }

    setTime = () => {
        const time = moment().format("hh:mm A")
        if (time) {
            this.setState({
                time: time
            })
        }
    }

    setDate = () => {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let dateValue = moment();
        let month = dateValue.month()
        let date = moment().format("DD")
        let year = moment().format("YYYY")
        let finalDate = months[month] + ' ' + date + ', ' + year
        if (finalDate) {
            this.setState({
                today: finalDate
            })
        }
    }

    showDropdown = () => {
        this.setState(prevState => ({
            showUserDropdown: !prevState.showUserDropdown
        }))
    }

    showCalenderDropdown = () => {
        this.setState(prevState => ({
            calenderDropdown: !prevState.calenderDropdown,
            calenderStatus: ''
        }))
    }

    handleDayClick = (day) => {
        const range = DateUtils.addDayToRange(day, this.state);
        this.setState(range)
    }

    dashboardDetails = (event) => {
        event.preventDefault()
        let from = moment(this.state.from).format('YYYY-MM-DD')
        let to = moment(this.state.to).format('YYYY-MM-DD')
        if (from && to) {
            this.showCalenderDropdown()
            this.props.setCalenderDates(from, to)
            this.setState({
                from: undefined,
                to: undefined,
                range: undefined,
            })
        }
    }

    logoutUser = () => {
        userLogout().then(() => {
            localStorage.removeItem("isLoggedIn")
            this.props.history.push("/login");
        })
    }

    changeCalender = (keyword) => {
        this.setState({
            calenderStatus: keyword
        }, () => {
            this.props.calenderStatus(this.state.calenderStatus)
        })
    }

    render() {
        const { time, today, showUserDropdown, calenderStatus, calenderDropdown, from, to, numberOfMonths } = this.state
        const modifiers = { start: from, end: to };
        return (
            <header id="header" className="d-flex align-items-center">
                <div className="d-flex w-100">
                    <div className="col pl-0 d-flex align-items-center">
                        <h3 className="m-0">{this.props.currentRoute}</h3>
                    </div>
                    <div className="col-auto pr-0 d-flex">
                        {
                            this.props && this.props.currentRoute == 'Dashboard' &&
                            <div className="dashboard-sort d-flex h-100 align-items-center">
                                <ul>
                                    <li><a className={calenderStatus == 'daily' ? "pointer active" : "pointer"} onClick={(e) => this.changeCalender('daily')}>Daily</a></li>
                                    <li><a className={calenderStatus == 'weekly' ? "pointer active" : "pointer"} onClick={(e) => this.changeCalender('weekly')}>Weekly</a></li>
                                    <li><a className={calenderStatus == 'monthly' ? "pointer active" : "pointer"} onClick={(e) => this.changeCalender('monthly')}>Monthly</a></li>
                                    <li>
                                        <form>
                                            <div className="input-group mb-2">
                                                <input type="text" className="form-textarea" placeholder="Period" />
                                                <div className="input-group-append">
                                                    <button onClick={this.showCalenderDropdown} type="button" className="btn h-100 input-group-text"><span className="fi flaticon-calendar"></span></button>
                                                    <div className={calenderDropdown ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="dropdownMenuButton">
                                                        <DayPicker
                                                            numberOfMonths={numberOfMonths}
                                                            selectedDays={[from, { from, to }]}
                                                            modifiers={modifiers}
                                                            onDayClick={this.handleDayClick}
                                                        />
                                                        <button onClick={(e) => this.dashboardDetails(e)} className={(from && to) ? "btn btn-primary ml-3" : "btn btn-primary ml-3 disabled"}>See result</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </li>
                                </ul>
                            </div>
                        }
                        <div className="date-time d-flex h-100 align-items-center">
                            <h3 className="pr-3">{time}</h3>
                            <h4 className="border-left py-2 pl-3 text-uppercase">{today}</h4>
                        </div>
                        {/* <div className="dropdown notifications-menu">
                             <button type="button" className="btn btn-primary notification position-relative ml-3">
                                <i className="fi flaticon-notification"></i>
                                <span className="sr-only">unread messages</span>
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <div className="notification-header d-flex align-items-center">
                                    <h6>File Uploading</h6>
                                </div>
                                <div className="notification-container">
                                </div>
                            </div> 
                        </div>*/}
                        <div className="dropdown user user-menu ml-3">
                            <button onClick={this.showDropdown} className="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <img src="/media/user-icon.png" alt="Profile" />
                            </button>
                            <div className={showUserDropdown ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="dropdownMenuButton">
                                <div className="card">
                                    <div className="card-header py-3">
                                        <div className="d-flex">
                                            {/*<div className="col-auto pl-0">
                                                 <div className="avatar">{this.getAvatar(this.props.user.name)}</div> 
                                            </div>*/}
                                            <div className="col px-0">
                                                <h5 className="name mb-0">{this.props && this.props.user.name}</h5>
                                                <p className="mb-0">{this.props && this.props.user.email}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer py-3">
                                        <button onClick={this.logoutUser} className="btn btn-primary w-100">Logout</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header >
        )
    }
}

export default Navbar