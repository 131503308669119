import React, { Component } from 'react'
import CommonPagination from '../../../commonComponents/CommonPagination';
import { getReferralReport } from '../../../../api/report';

class Referral extends Component {

    constructor(props) {
        super(props);
        this.state = {
            referrals: [],
            total: 0,
            noOfPages: 0,
            page: 0,
            perPage: 0
        }
    }

    componentDidUpdate = (prevProps) => {
        if ((this.props.fromDate != prevProps.fromDate) || (this.props.toDate != prevProps.toDate)) {
            this.referralReport(true, 1, this.props.fromDate, this.props.toDate)
        }
    }

    referralReport = (limit, page, fromDate, toDate) => {
        let params = {
            limit,
            page,
            fromDate,
            toDate
        }
        getReferralReport(params).then((response) => {
            this.setState({
                referrals: response.data.referral,
                total: response.data.total,
                perPage: response.data.perPage,
                noOfPages: response.data.noOfPages,
                page: page
            })
        })
    }

    render() {
        const { referrals, perPage, page, noOfPages } = this.state

        return (
            <div className="card">
                <div className="card-header p-3 d-flex justify-content-between align-items-center">
                    <h5 className="m-0">Referral Report</h5>
                </div>
                <div className="card-body p-0">
                    {
                        referrals && referrals.length > 0 &&
                        <table className="table mb-0 table-layout-fixed">
                            <thead>
                                <tr>
                                    <th scope="col" width="10%">No</th>
                                    <th scope="col">Vendor</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Customer Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    referrals && referrals.length > 0 &&
                                    referrals.map((element, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{perPage * (page - 1) + (index + 1)}</td>
                                                <td>{element.vendorName}</td>
                                                <td>{element.amount} {element.currency}</td>
                                                <td>{element.customerCount}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    }
                    {
                        !referrals &&
                        <h6 className="text-center mt-3">There are no referrals on this day/selected date range.</h6>
                    }
                    <CommonPagination onPageChange={this.handlePageClick} pageCount={noOfPages} selectedPage={page} />
                </div>
            </div>
        )
    }
}

export default Referral