import React, { Component } from 'react'
import { getDashboardDetails } from '../../../api/dashboard';
import BarChart from './BarChart';
import PieChart from './PieChart'
import { Link } from 'react-router-dom';
import moment from 'moment';

export default class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            details: {},
            userCount: 0,
            vouchersRedeemed: 0,
            paymentsCount: 0,
            categoryVouchers: [],
            vendorVouchers: [],
            packagesCount: [],
            barChartData: [],
            graphXRange: 0,
            pieChartValue: []
        }
    }

    componentDidMount = () => {
        let fromDate = ''
        let toDate = ''
        if (this.props && this.props.calender && this.props.calender == 'daily') {
            fromDate = moment().format("YYYY-MM-DD")
            toDate = moment().add(1, 'd').format("YYYY-MM-DD")
            this.getDashBoard(fromDate, toDate)
        }
        else if (this.props && this.props.calender == 'custom') {
            fromDate = this.props.from
            toDate = this.props.to
            this.getDashBoard(fromDate, toDate)
        }
    }

    componentDidUpdate = (prevProps) => {
        if ((this.props.calender != prevProps.calender) || (this.props.from != prevProps.from) || (this.props.to != prevProps.to)) {
            let calenderStatus = this.props.calender
            let fromDate = ''
            let toDate = ''
            if (calenderStatus == 'daily') {
                fromDate = moment().format("YYYY-MM-DD")
                toDate = moment().add(1, 'd').format("YYYY-MM-DD")
            }
            else if (calenderStatus == 'weekly') {
                fromDate = moment().add(-7, 'd').format("YYYY-MM-DD")
                toDate = moment().add(1, 'd').format("YYYY-MM-DD")
            }
            else if (calenderStatus == 'monthly') {
                fromDate = moment().add(-30, 'd').format("YYYY-MM-DD")
                toDate = moment().add(1, 'd').format("YYYY-MM-DD")
            }
            else if (this.props && this.props.calender == 'custom') {
                fromDate = moment(this.props.from).format("YYYY-MM-DD")
                toDate = moment(this.props.to).add(1, 'd').format("YYYY-MM-DD")
            }

            this.getDashBoard(fromDate, toDate)
        }
    }

    getDashBoard = (from, to) => {
        let params = {}
        params.toDate = to
        params.fromDate = from
        getDashboardDetails(params).then((response) => {
            this.setState({
                details: response.data,
                userCount: response.data.userCount,
                vouchersRedeemed: response.data.vouchersRedeemed,
                paymentsCount: response.data.paymentsCount,
                categoryVouchers: response.data.categoryVouchers || [],
                vendorVouchers: response.data.vendorVouchers || [],
                packagesCount: response.data.packagesCount || [],
            }, () => {
                this.getBarChartData()
                this.getPieChartData()
            })
        })
    }

    getBarChartData = () => {
        let graphXRange = 0;
        let barChartData = []
        this.state.packagesCount.forEach((item) => {
            barChartData.push({
                label: item.name,
                value: item.count
            })
            if (graphXRange < item.count) {
                graphXRange = Math.ceil(item.count * 110 / 100)
            }
        })
        this.setState({
            graphXRange,
            barChartData,
        })
    }

    getPieChartData = () => {
        let pieChartValue = []
        let othersValue = 0

        this.state.categoryVouchers.forEach((category, index) => {
            if (index < 5) {
                pieChartValue.push({
                    label: category.name,
                    value: category.count
                })
            } else {
                othersValue += category.count
            }
        })

        if (othersValue > 0) {
            pieChartValue.push({
                label: 'Others',
                value: othersValue
            })
        }


        this.setState({
            pieChartValue,
        })
    }

    render() {

        const { userCount, vouchersRedeemed, paymentsCount, pieChartValue, vendorVouchers, packagesCount, categoryVouchers } = this.state
        const { barChartData, graphXRange } = this.state

        return (
            <main id="main">
                <section className="content-wrapper dashboard-wrapper d-flex flex-column">
                    <div className="row">
                        <div className="col-4">
                            <div className="box h-100 radius-10 p-4">
                                <div className="d-flex align-items-center justify-content-around">
                                    <div className="col">
                                        <h3 className="dashb-count m-0">{userCount}</h3>
                                        <h4 className="dashb-heading m-0">Customer registration</h4>
                                    </div>
                                    <div className="col">
                                        <span className="dashb-wave">
                                            <svg
                                                width="187px" height="69px">

                                                <defs>
                                                    <linearGradient id="gradient" x1="0%" y1="80%" x2="0%" y2="0%" >
                                                        <stop offset="0%" stopColor="#ffffff" />
                                                        <stop offset="100%" stopColor="#0078ff" />
                                                    </linearGradient>
                                                </defs>
                                                <path className="pathGradient" fillRule="evenodd" opacity="0.161" fill="rgb(0, 120, 255)"
                                                    d="M180.662,8.125 C180.662,7.379 173.167,9.330 151.597,20.556 C139.483,26.860 132.703,13.243 122.271,13.243 C111.735,13.243 101.950,25.422 92.944,34.083 C81.540,45.048 68.343,25.519 63.093,18.362 C51.530,2.598 41.290,8.999 34.159,16.900 C18.999,33.697 4.702,3.372 4.702,3.372 L4.702,67.353 L180.793,67.353 C180.793,67.353 180.662,11.568 180.662,8.125 Z" />
                                                <path className="pathLine" fillRule="evenodd" stroke="rgb(0, 120, 255)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="none"
                                                    d="M4.702,3.372 C4.702,3.372 18.999,33.697 34.159,16.900 C41.290,8.999 51.530,2.598 63.093,18.362 C68.343,25.519 81.540,45.048 92.944,34.083 C101.950,25.422 111.735,13.243 122.271,13.243 C132.703,13.243 139.483,26.860 151.597,20.556 C173.167,9.330 180.418,8.430 180.662,8.125 " />
                                                <path className="pathCircle pathCircle2" fillRule="evenodd" stroke="rgb(0, 120, 255)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="rgb(255, 255, 255)"
                                                    d="M48.703,7.031 C50.282,7.031 51.562,8.311 51.562,9.891 C51.562,11.470 50.282,12.750 48.703,12.750 C47.124,12.750 45.844,11.470 45.844,9.891 C45.844,8.311 47.124,7.031 48.703,7.031 Z" />
                                                <path className="pathCircle " fillRule="evenodd" stroke="rgb(0, 120, 255)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="rgb(255, 255, 255)"
                                                    d="M4.703,1.031 C6.282,1.031 7.562,2.311 7.562,3.891 C7.562,5.470 6.282,6.750 4.703,6.750 C3.124,6.750 1.844,5.470 1.844,3.891 C1.844,2.311 3.124,1.031 4.703,1.031 Z" />
                                                <path className="pathCircle pathCircle3" fillRule="evenodd" stroke="rgb(0, 120, 255)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="rgb(255, 255, 255)"
                                                    d="M85.703,35.031 C87.282,35.031 88.562,36.311 88.562,37.891 C88.562,39.470 87.282,40.750 85.703,40.750 C84.124,40.750 82.844,39.470 82.844,37.891 C82.844,36.311 84.124,35.031 85.703,35.031 Z" />
                                                <path className="pathCircle pathCircle4" fillRule="evenodd" stroke="rgb(0, 120, 255)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="rgb(255, 255, 255)"
                                                    d="M120.703,11.031 C122.282,11.031 123.562,12.311 123.562,13.891 C123.562,15.470 122.282,16.750 120.703,16.750 C119.124,16.750 117.844,15.470 117.844,13.891 C117.844,12.311 119.124,11.031 120.703,11.031 Z" />
                                                <path className="pathCircle pathCircle5" fillRule="evenodd" stroke="rgb(0, 120, 255)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="rgb(255, 255, 255)"
                                                    d="M181.703,7.031 C183.282,7.031 184.562,8.311 184.562,9.891 C184.562,11.470 183.282,12.750 181.703,12.750 C180.124,12.750 178.844,11.470 178.844,9.891 C178.844,8.311 180.124,7.031 181.703,7.031 Z" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="box h-100 radius-10 p-4">
                                <div className="d-flex align-items-center justify-content-around">
                                    <div className="col">
                                        <h3 className="dashb-count m-0">{vouchersRedeemed}</h3>
                                        <h4 className="dashb-heading m-0">Voucher Redemption</h4>
                                    </div>
                                    <div className="col">
                                        <span className="dashb-wave">
                                            <svg width="178px" height="48px">
                                                <defs>
                                                    <linearGradient id="gradient2" x1="0%" y1="80%" x2="0%" y2="0%" >
                                                        <stop offset="0%" stopColor="#ffffff" />
                                                        <stop offset="50%" stopColor="rgb(55, 229, 212)" />
                                                        <stop offset="100%" stopColor="rgb(55, 229, 212)" />
                                                    </linearGradient>
                                                </defs>
                                                <path className="pathGradient2" fillRule="evenodd" opacity="0.161" fill="rgb(55, 229, 212)"
                                                    d="M172.049,7.344 C172.049,6.837 155.557,33.540 144.028,29.983 C132.342,26.378 125.895,3.861 115.882,3.861 C105.771,3.861 96.260,22.943 87.736,29.486 C77.043,37.694 64.441,27.620 59.339,23.017 C52.136,16.520 41.938,5.921 31.318,6.349 C20.263,6.794 3.047,20.032 3.047,20.032 L3.047,46.900 L172.174,46.900 C172.174,46.900 172.049,20.384 172.049,7.344 Z" />
                                                <path className="pathLine" fillRule="evenodd" stroke="rgb(55, 229, 212)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="none"
                                                    d="M3.047,18.032 C3.047,18.032 20.263,4.794 31.318,4.349 C41.938,3.921 52.136,14.520 59.339,21.017 C64.441,25.620 77.043,35.694 87.736,27.486 C96.260,20.943 105.771,1.861 115.882,1.861 C125.895,1.861 132.342,24.378 144.028,27.983 C155.557,31.540 171.689,5.552 171.923,5.344 " />
                                                <path className="pathCircle" fillRule="evenodd" stroke="rgb(55, 229, 212)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="rgb(255, 255, 255)"
                                                    d="M4.535,15.170 C6.112,15.170 7.390,16.449 7.390,18.027 C7.390,19.605 6.112,20.884 4.535,20.884 C2.958,20.884 1.680,19.605 1.680,18.027 C1.680,16.449 2.958,15.170 4.535,15.170 Z" />
                                                <path className="pathCircle pathCircle2" fillRule="evenodd" stroke="rgb(55, 229, 212)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="rgb(255, 255, 255)"
                                                    d="M31.535,2.170 C33.112,2.170 34.390,3.449 34.390,5.027 C34.390,6.605 33.112,7.884 31.535,7.884 C29.958,7.884 28.680,6.605 28.680,5.027 C28.680,3.449 29.958,2.170 31.535,2.170 Z" />
                                                <path className="pathCircle pathCircle3" fillRule="evenodd" stroke="rgb(55, 229, 212)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="rgb(255, 255, 255)"
                                                    d="M75.535,28.170 C77.112,28.170 78.390,29.449 78.390,31.027 C78.390,32.605 77.112,33.884 75.535,33.884 C73.958,33.884 72.680,32.605 72.680,31.027 C72.680,29.449 73.958,28.170 75.535,28.170 Z" />
                                                <path className="pathCircle pathCircle4" fillRule="evenodd" stroke="rgb(55, 229, 212)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="rgb(255, 255, 255)"
                                                    d="M114.535,1.170 C116.112,1.170 117.390,2.449 117.390,4.027 C117.390,5.605 116.112,6.884 114.535,6.884 C112.958,6.884 111.680,5.605 111.680,4.027 C111.680,2.449 112.958,1.170 114.535,1.170 Z" />
                                                <path className="pathCircle pathCircle5" fillRule="evenodd" stroke="rgb(55, 229, 212)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="rgb(255, 255, 255)"
                                                    d="M172.535,5.170 C174.112,5.170 175.390,6.449 175.390,8.027 C175.390,9.605 174.112,10.884 172.535,10.884 C170.958,10.884 169.680,9.605 169.680,8.027 C169.680,6.449 170.958,5.170 172.535,5.170 Z" />
                                                <path className="pathCircle pathCircle6" fillRule="evenodd" stroke="rgb(55, 229, 212)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="rgb(255, 255, 255)"
                                                    d="M147.535,26.170 C149.112,26.170 150.390,27.449 150.390,29.027 C150.390,30.605 149.112,31.884 147.535,31.884 C145.958,31.884 144.680,30.605 144.680,29.027 C144.680,27.449 145.958,26.170 147.535,26.170 Z" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="box h-100 radius-10 p-4">
                                <div className="d-flex align-items-center justify-content-around">
                                    <div className="col">
                                        <h3 className="dashb-count m-0">{paymentsCount}</h3>
                                        <h4 className="dashb-heading m-0">Payments</h4>
                                    </div>
                                    <div className="col">
                                        <span className="dashb-wave">
                                            <svg width="204px" height="56px">
                                                <defs>
                                                    <linearGradient id="gradient3" x1="0%" y1="80%" x2="0%" y2="0%" >
                                                        <stop offset="0%" stopColor="#ffffff" />
                                                        <stop offset="50%" stopColor="rgb(107, 193, 66)" />
                                                        <stop offset="100%" stopColor="rgb(107, 193, 66)" />
                                                    </linearGradient>
                                                </defs>
                                                <path className="pathGradient3" fillRule="evenodd" opacity="0.161" fill="rgb(107, 193, 66)"
                                                    d="M198.647,30.467 C198.647,29.485 181.452,35.089 166.800,36.723 C148.477,38.766 146.192,20.382 134.954,15.550 C121.523,9.775 113.314,23.343 103.108,29.023 C91.009,35.757 77.042,29.541 70.977,23.249 C55.151,6.831 51.206,19.860 39.273,24.693 C20.509,32.291 7.284,3.038 7.284,3.038 L7.284,54.046 L198.647,54.046 C198.647,54.046 198.647,38.367 198.647,30.467 Z" />
                                                <path className="pathLine" fillRule="evenodd" stroke="rgb(107, 193, 66)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="none"
                                                    d="M7.284,3.038 C7.284,3.038 20.509,32.291 39.273,24.693 C51.206,19.860 55.151,6.831 70.977,23.249 C77.042,29.541 91.009,35.757 103.108,29.023 C113.314,23.343 121.523,9.775 134.954,15.550 C146.192,20.382 148.477,38.766 166.800,36.723 C181.452,35.089 198.097,30.868 198.362,30.467 " />
                                                <path className="pathCircle" fillRule="evenodd" stroke="rgb(107, 193, 66)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="rgb(255, 255, 255)"
                                                    d="M4.595,1.550 C6.172,1.550 7.450,2.830 7.450,4.408 C7.450,5.987 6.172,7.267 4.595,7.267 C3.018,7.267 1.740,5.987 1.740,4.408 C1.740,2.830 3.018,1.550 4.595,1.550 Z" />
                                                <path className="pathCircle pathCircle2" fillRule="evenodd" stroke="rgb(107, 193, 66)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="rgb(255, 255, 255)"
                                                    d="M56.595,12.550 C58.172,12.550 59.450,13.830 59.450,15.408 C59.450,16.987 58.172,18.267 56.595,18.267 C55.018,18.267 53.740,16.987 53.740,15.408 C53.740,13.830 55.018,12.550 56.595,12.550 Z" />
                                                <path className="pathCircle pathCircle3" fillRule="evenodd" stroke="rgb(107, 193, 66)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="rgb(255, 255, 255)"
                                                    d="M93.595,29.550 C95.172,29.550 96.450,30.830 96.450,32.408 C96.450,33.987 95.172,35.267 93.595,35.267 C92.018,35.267 90.740,33.987 90.740,32.408 C90.740,30.830 92.018,29.550 93.595,29.550 Z" />
                                                <path className="pathCircle pathCircle4" fillRule="evenodd" stroke="rgb(107, 193, 66)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="rgb(255, 255, 255)"
                                                    d="M128.595,12.550 C130.172,12.550 131.450,13.830 131.450,15.408 C131.450,16.987 130.172,18.267 128.595,18.267 C127.018,18.267 125.740,16.987 125.740,15.408 C125.740,13.830 127.018,12.550 128.595,12.550 Z" />
                                                <path className="pathCircle pathCircle5" fillRule="evenodd" stroke="rgb(107, 193, 66)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="rgb(255, 255, 255)"
                                                    d="M198.595,28.550 C200.172,28.550 201.450,29.830 201.450,31.408 C201.450,32.987 200.172,34.267 198.595,34.267 C197.018,34.267 195.740,32.987 195.740,31.408 C195.740,29.830 197.018,28.550 198.595,28.550 Z" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row h-100">
                        <div className="col-3 mt-3">
                            <div className="row h-100">
                                <div className="col-12">
                                    <div className="box h-100 radius-10 p-4 anv-wrapper d-flex flex-column justify-content-center align-items-start">
                                        <h2 className="m-0 anv-heading">  Add New Vendor </h2>
                                        <p className="m-0 mt-1">Please click here to add new vendor </p>
                                        <Link className="btn btn-primary px-4 mt-2" to="/vendor">ADD NEW</Link>
                                    </div>
                                </div>
                                <div className="col-12 mt-3">
                                    <div className="box h-100 radius-10 d-flex flex-column">
                                        <div className="chart-header px-4 pt-4">
                                            <h4 className="m-0">Top Categories</h4>
                                            <p className="m-0">You can see all listing in categories page.</p>
                                        </div>
                                        <div className="chart-body mt-3 scroll-4 px-4 pb-4">
                                            {
                                                categoryVouchers && categoryVouchers.length > 0 &&
                                                <PieChart pieChartValue={pieChartValue} />
                                            }
                                            {
                                                categoryVouchers && categoryVouchers.length == 0 &&
                                                <p className="text-center">No data available</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-5 mt-3">
                            <div className="box h-100 radius-10 px-4 pt-4 pb-0">
                                <div className="chart-header d-flex justify-content-between align-items-center">
                                    <div>
                                        <h4 className="m-0">Top Vendors</h4>
                                        <p className="m-0 mt-1">You can see all vendors by clicking View All.</p>
                                    </div>
                                    <div>
                                        {/* <div className="form-group search position-relative mb-0">
                                            <input className="form-control pr-4" type="search" placeholder="Search Vendors" aria-label="Search" value="" />
                                            <i className="fi flaticon-search position-absolute"></i>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="chart-body mt-3 table-responsive scroll-3">
                                    {
                                        vendorVouchers && vendorVouchers.length > 0 &&
                                        <table className="table mb-0 table-layout-fixed table-borderless">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Vouchers</th>
                                                    <th>Location</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    vendorVouchers.map((vendor, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{vendor.name}</td>
                                                                <td>{vendor.count}</td>
                                                                <td>{vendor.location} </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    }
                                    {
                                        vendorVouchers && vendorVouchers.length == 0 &&
                                        <p className="text-center">No data available</p>
                                    }
                                </div>
                                <div className="chart-footer d-flex justify-content-end border-top py-3 align-items-center">
                                    <p className="m-0 p-0 pr-4">Top Vendors are listed</p>
                                    <Link className="btn btn-primary px-4" to="/vendor">View All Vendors</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 mt-3">
                            <div className="box h-100 radius-10 p-4">
                                <div className="chart-header">
                                    <h4 className="m-0">Top Packages</h4>
                                    <p className="m-0 mt-1">You can see all  the trending list in packages page.</p>
                                </div>
                                <div className="chart-body mt-3">
                                    {
                                        packagesCount && packagesCount.length > 0 &&
                                        <BarChart barChartData={barChartData} graphXRange={graphXRange} />
                                    }
                                    {
                                        packagesCount && packagesCount.length == 0 &&
                                        <p className="text-center">No data available</p>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </main >
        )
    }
}