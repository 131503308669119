import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const CommonConfirmationModal = (props) => {

    return (
        <Modal isOpen={props.isOpen}>
            <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
            <ModalBody>
                {props.body}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={props.submit}>Proceed</Button>
                <Button color="secondary" className="ml-2" onClick={props.toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
}

export default CommonConfirmationModal;