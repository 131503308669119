import React, { Component } from 'react'
import ManageCategory from './Manage'
import DeleteCategory from './Delete'
import CommonPagination from '../../commonComponents/CommonPagination';
import { getCategory, deleteCategory } from '../../../api/category'
import CommonAlert from '../../commonComponents/CommonAlert';

class Category extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            total: 0,
            noOfPages: 0,
            perPage: 0,
            selectedPage: 1,
            searchKey: '',
            manageCategoryModal: false,
            editCategory: '',
            editCategoryImage: '',
            editCategoryId: 0,
            deleteCategoryId: 0,
            deleteCategoryModal: false,
            editCategoryStatus: false,
            mode: '',
            message: '',
            messageType: ''
        }
    }

    componentDidMount = () => {
        this.listCategory()
    }

    listCategory = (page = this.state.selectedPage, title = this.state.searchKey) => {
        let params = {
            title,
            page,
            count: 15
        }
        getCategory(params).then((response) => {
            this.setState({
                categories: response.data.categories,
                total: response.data.total,
                noOfPages: response.data.noOfPages,
                perPage: response.data.perPage,
                selectedPage: page
            })
        })
    }

    showAlert = (message, messageType) => {
        this.listCategory()
        this.setState({
            message,
            messageType
        }, () => {
            setTimeout(this.dismissAlert, 3000)
        })
    }

    dismissAlert = () => {
        this.setState({
            message: '',
            messageType: ''
        })
    }

    searchCategory = (key) => {
        this.setState({
            searchKey: key
        }, () => {
            this.listCategory(1)
        })
    }

    addCategory = () => {
        this.setState({
            mode: 'add'
        }, () => {
            this.toggleManageModal()
        })
    }

    toggleManageModal = () => {
        this.setState(prevState => ({
            manageCategoryModal: !prevState.manageCategoryModal
        }))
    }

    toggleEditModal = (name, imageURL, id, status) => {
        this.setState({
            editCategoryId: id,
            editCategory: name,
            editCategoryImage: imageURL,
            mode: 'edit',
            editCategoryStatus: status
        }, () => {
            this.toggleManageModal()
        })
    }

    deleteCategory = (id) => {
        this.setState({
            deleteCategoryId: id
        }, () => {
            this.toggleDeleteModal()
        })
    }

    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteCategoryModal: !prevState.deleteCategoryModal
        }))
    }

    submitDeleteCategory = () => {
        deleteCategory(this.state.deleteCategoryId).then((response) => {
            this.toggleDeleteModal()
            this.listCategory()
            this.showAlert(response.message, 'success')
        }).catch((error) => {
            this.toggleDeleteModal()
            this.showAlert(error.message, 'danger')
        })
    }

    handlePageClick = (page) => {
        this.listCategory(page.selected + 1)
    }

    render() {
        const { categories, searchKey, total, noOfPages, perPage, selectedPage, message, messageType, manageCategoryModal, editCategory, editCategoryImage, editCategoryId, deleteCategoryModal, mode, editCategoryStatus } = this.state
        return (
            <main id="main">
                <section className="content-wrapper box-shadow">
                    <div className="one-column-right h-100">
                        <div className="box radius-10 tab-style2 h-100">
                            <CommonAlert color={messageType} show={!!messageType} message={message} />
                            <div className="box-header px-3 pt-4 pb-3">
                                <div className="y-center">
                                    <div className="col pl-0">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h5 className="pl-2">Manage Category</h5>
                                            <button className="btn btn-primary bg-rust ml-4" onClick={this.addCategory} >
                                                <i className="fi flaticon-add-1 small fs-10"></i>
                                                <span className="pl-2">Add Category</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-auto px-2">
                                        <div className="form-row align-items-center">
                                            <div className="form-group search position-relative mb-0 mr-3">
                                                <input className="form-control pr-4" type="search" value={searchKey} onChange={(e) => this.searchCategory(e.target.value)} placeholder="Search category"
                                                    aria-label="Search" />
                                                <i className="fi flaticon-search position-absolute"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body px-3">
                                <div className="card border-0">
                                    <div className="card-body p-0">
                                        <div className="table-responsive radius-10 border scroll">
                                            <table className="table mb-0 table-layout-fixed">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="text-center" width="5%">No</th>
                                                        <th scope="col">Category</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Edit</th>
                                                        <th scope="col" className="text-center">Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        categories && categories.length > 0 &&
                                                        categories.map((category, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="text-center">{perPage * (selectedPage - 1) + (index + 1)}</td>
                                                                    <td>{category.name}</td>
                                                                    <td>{category.active ? "Active" : "Inactive"}</td>
                                                                    <td className="c-clay-creek edit"><i onClick={() => this.toggleEditModal(category.name, category.imageURL, category.id, category.active)} className="fi flaticon-write"></i></td>
                                                                    <td className="text-center delete"><i onClick={(e) => this.deleteCategory(category.id)} className="fi c-red flaticon-delete" ></i></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        categories && categories.length == 0 &&
                                                        <tr>
                                                            <td colSpan="8" className="text-center">
                                                                <h6 className="mt-3">No categories are added</h6>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <CommonPagination onPageChange={this.handlePageClick} pageCount={noOfPages} selectedPage={selectedPage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ManageCategory modal={manageCategoryModal} mode={mode} toggle={this.toggleManageModal} name={editCategory} imageURL={editCategoryImage} id={editCategoryId} showAlert={this.showAlert} active={editCategoryStatus} />
                <DeleteCategory modal={deleteCategoryModal} toggle={this.toggleDeleteModal} submitDeleteCategory={this.submitDeleteCategory} />
            </main >
        )
    }
}

export default Category

