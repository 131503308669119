import React from "react";
import { compose, withProps } from "recompose";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";
import { GOOGLE_API_KEY } from '../../../../config'

const MapComponent = compose(
    withProps({
        googleMapURL:
            `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `300px` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
)(props => (
    <GoogleMap center={{ lat: props.latitude || 0, lng: props.longitude || 0 }} onClick={props.onMarkerClick} zoom={props.latitude == 0 && props.longitude == 0 ? 1 : 8}>
        {props.isMarkerShown && (
            <Marker position={{ lat: props.latitude, lng: props.longitude }} />
        )}
    </GoogleMap>
));

export default MapComponent