import React, { Component, Fragment } from 'react'
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import CommonPagination from '../../commonComponents/CommonPagination'
import moment from 'moment'
import { getTransactions } from '../../../api/customer';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

class Transactions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            transactions: [],
            page: 0,
            perPage: 0,
            total: 0,
            noOfPages: 0,
            status: '',
            popoverOpen: false,
            popoverId: 0,
            calenderDropdown: false,
            numberOfMonths: 2,
            from: undefined,
            to: undefined,
            range: undefined,
            fromDate: '',
            toDate: ''
        }
    }

    componentDidMount = () => {
        this.listTransactions(1, '')
    }

    listTransactions = (page, status, fromDate, toDate) => {
        let params = {
            page,
            status,
            count: 15,
            fromDate,
            toDate
        }
        getTransactions(params).then((response) => {
            this.setState({
                transactions: response.data.transactions,
                page: page,
                perPage: response.data.perPage,
                total: response.data.total,
                noOfPages: response.data.noOfPages,
            })
        })
    }

    handlePageClick = (page) => {
        this.setState({
            page: page.selected + 1
        }, () => {
            this.listTransactions(this.state.page, this.state.status)
        })
    }

    searchStatus = (status) => {
        this.setState({
            status: status
        }, () => {
            this.listTransactions(1, this.state.status)
        })
    }

    onHover = (id) => {
        this.setState({
            popoverOpen: true,
            popoverId: id
        })
    }

    onHoverLeave = () => {
        this.setState({
            popoverOpen: false
        })
    }

    handleDayClick = (day) => {
        const range = DateUtils.addDayToRange(day, this.state);
        this.setState(range)
    }

    showCalenderDropdown = () => {
        this.setState(prevState => ({
            calenderDropdown: !prevState.calenderDropdown,
        }))
    }

    transactionDetails = (event) => {
        event.preventDefault()
        let fromDate = moment(this.state.from).format("YYYY-MM-DD")
        let toDate = moment(this.state.to).add(1, 'd').format("YYYY-MM-DD")
        this.setState({
            fromDate: moment(this.state.from).format("YYYY-MM-DD"),
            toDate: moment(this.state.to).format("YYYY-MM-DD")
        }, () => {
            this.showCalenderDropdown()
            this.listTransactions(1, "", fromDate, toDate)
        })
    }

    render() {

        const { transactions, page, perPage, noOfPages, status, popoverId, popoverOpen, numberOfMonths, from, to, calenderDropdown, fromDate, toDate } = this.state
        const modifiers = { start: from, end: to };

        return (
            <main id="main">
                <section className="content-wrapper box-shadow">
                    <div className="one-column-right h-100">
                        <div className="box radius-10 tab-style2 h-100">
                            <div className="box-header px-3 pt-4 pb-3">
                                <div className="y-center">
                                    <div className="col pl-0">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h5 className="pl-2">List Transactions</h5>
                                        </div>
                                    </div>
                                    <div className="col-auto ">
                                        <div className="d-flex align-items-center justify-content-end">
                                            {
                                                fromDate == toDate &&
                                                <p className="mb-0 mr-3"><strong>{toDate}</strong></p>
                                            }
                                            {
                                                fromDate != toDate &&
                                                <p className="mb-0 mr-3"><strong>{fromDate} - {toDate}</strong></p>
                                            }
                                            <button onClick={this.showCalenderDropdown} className="btn h-100 input-group-text calender-btn pointer"><span className="fi flaticon-calendar"></span></button>
                                            <div className={calenderDropdown ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="dropdownMenuButton">
                                                <DayPicker
                                                    numberOfMonths={numberOfMonths}
                                                    selectedDays={[from, { from, to }]}
                                                    modifiers={modifiers}
                                                    onDayClick={this.handleDayClick}
                                                />
                                                <button onClick={(e) => this.transactionDetails(e)} className={(from && to) ? "btn btn-primary ml-3" : "btn btn-primary ml-3 disabled"}>See result</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto px-2">
                                        <div className="form-row align-items-center">
                                            <div className="form-group form-inline mb-0 mr-3">
                                                <select className="form-control" value={status} onChange={(e) => this.searchStatus(e.target.value)}>
                                                    <option value="">Search by status</option>
                                                    <option value="success">Success</option>
                                                    <option value="pending">Pending</option>
                                                    <option value="failed">Failed</option>
                                                    <option value="refunded">Refunded</option>
                                                    <option value="errored">Errored</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body px-3">
                                <div className="card border-0">
                                    <div className="card-body p-0">
                                        <div className="table-responsive radius-10 border scroll">
                                            <table className="table mb-0 table-layout-fixed">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" width="5%">No</th>
                                                        <th scope="col">Transaction ID</th>
                                                        <th scope="col" width="10%">Method</th>
                                                        <th scope="col">Customer Name</th>
                                                        <th scope="col">Customer Email</th>
                                                        <th scope="col" width="10%">Amount</th>
                                                        <th scope="col" width="10%">Status</th>
                                                        <th scope="col">Start Time </th>
                                                        <th scope="col">Updated Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        transactions && transactions.length > 0 &&
                                                        transactions.map((transaction, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{perPage * (page - 1) + (index + 1)}</td>
                                                                    <td>{transaction.transactionId}</td>
                                                                    <td>{transaction.method}</td>
                                                                    <td>{transaction.name}</td>
                                                                    <td>{transaction.email}</td>
                                                                    <td>{transaction.amount} {transaction.currency}</td>
                                                                    {
                                                                        transaction.status != 'errored' &&
                                                                        <td>{transaction.status}</td>
                                                                    }
                                                                    {
                                                                        transaction.status == 'errored' &&
                                                                        <Fragment>
                                                                            <td>{transaction.status} <img id={'popover-' + transaction.id} src="/media/question.png" onMouseEnter={() => this.onHover(transaction.id)} onMouseLeave={this.onHoverLeave} alt="Ferris Wheel" /></td>
                                                                            {
                                                                                popoverId == transaction.id &&
                                                                                <Popover
                                                                                    placement="right"
                                                                                    isOpen={popoverOpen}
                                                                                    target={'popover-' + transaction.id}>
                                                                                    <PopoverHeader>Reason for error</PopoverHeader>
                                                                                    <PopoverBody>{transaction.errorReason}</PopoverBody>
                                                                                </Popover>
                                                                            }

                                                                        </Fragment>
                                                                    }
                                                                    <td>{moment(transaction.startTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                                    <td>{moment(transaction.updatedTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        transactions && transactions.length == 0 &&
                                                        <tr>
                                                            <td colSpan="8" className="text-center">
                                                                <h6 className="mt-3">No transactions for this filter</h6>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <CommonPagination onPageChange={this.handlePageClick} pageCount={noOfPages} selectedPage={page} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}

export default Transactions