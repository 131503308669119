import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: ''
        }
    }

    activeTab = (tab) => {
        this.setState({
            tab: tab
        })
        this.props.changeRoute(tab)
    }

    componentDidMount = () => {
        if (window.location.href.includes('/dashboard')) {
            this.setState({
                tab: 'Dashboard'
            }, () => {
                this.props.changeRoute('Dashboard')
            })
        }
        if (window.location.href.includes('/category')) {
            this.setState({
                tab: 'Category'
            }, () => {
                this.props.changeRoute('Category')
            })
        }
        if (window.location.href.includes('/vendor')) {
            this.setState({
                tab: 'Vendor'
            }, () => {
                this.props.changeRoute('Vendor')
            })
        }
        if (window.location.href.includes('/voucher')) {
            this.setState({
                tab: 'Voucher'
            }, () => {
                this.props.changeRoute('Voucher')
            })
        }
        if (window.location.href.includes('/package')) {
            this.setState({
                tab: 'Package'
            }, () => {
                this.props.changeRoute('Package')
            })
        }
        if (window.location.href.includes('/customers')) {
            this.setState({
                tab: 'Customer'
            }, () => {
                this.props.changeRoute('Customer')
            })
        }
        if (window.location.href.includes('/user')) {
            this.setState({
                tab: 'User'
            }, () => {
                this.props.changeRoute('User')
            })
        }
        if (window.location.href.includes('/report')) {
            this.setState({
                tab: 'Report'
            }, () => {
                this.props.changeRoute('Report')
            })
        }
        if (window.location.href.includes('/transactions')) {
            this.setState({
                tab: 'Transaction'
            }, () => {
                this.props.changeRoute('Transactions')
            })
        }
    }


    render() {
        const { tab } = this.state
        return (
            <aside id="sidebar" className="100vh box-shadow">
                <div className="header">
                    <div className="d-flex y-center pb-3">
                        <div className="logo col p-0">
                            <span className="lg d-flex align-items-center">
                                <img src="/media/logo-ferris.png" alt="Ferris Wheel" />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="body">
                    <div className="sidebar-menu">
                        <ul className="nav flex-column">
                            <li onClick={this.activeTab.bind(this, 'Dashboard')} className={tab == 'Dashboard' ? "nav-item active" : "nav-item"} >
                                <Link className="nav-link" to="/dashboard"><i className="fi flaticon-speedometer"></i><span>Dashboard</span></Link>
                            </li>
                            {
                                this.props && this.props.userPermission && this.props.userPermission.length > 0 && this.props.userPermission.includes('/category') &&
                                <li onClick={this.activeTab.bind(this, 'Category')} className={tab == 'Category' ? "nav-item active" : "nav-item"}>
                                    <Link className="nav-link" to="/category" ><i className="fi flaticon-dashboard"></i><span>Categories</span></Link>
                                </li>
                            }
                            {
                                this.props && this.props.userPermission && this.props.userPermission.length > 0 && this.props.userPermission.includes('/vendor') &&
                                <li onClick={this.activeTab.bind(this, 'Vendor')} className={tab == 'Vendor' ? "nav-item active" : "nav-item"}>
                                    <Link className="nav-link" to="/vendor" ><i className="fi flaticon-shop"></i><span>Vendors</span></Link>
                                </li>
                            }
                            {
                                this.props && this.props.userPermission && this.props.userPermission.length > 0 && this.props.userPermission.includes('/voucher') &&
                                <li onClick={this.activeTab.bind(this, 'Voucher')} className={tab == 'Voucher' ? "nav-item active" : "nav-item"}>
                                    <Link className="nav-link" to="/voucher" ><i className="fi flaticon-coupon"></i><span>Vouchers</span></Link>
                                </li>
                            }
                            {
                                this.props && this.props.userPermission && this.props.userPermission.length > 0 && this.props.userPermission.includes('/package') &&
                                <li onClick={this.activeTab.bind(this, 'Package')} className={tab == 'Package' ? "nav-item active" : "nav-item"}>
                                    <Link className="nav-link" to="/package" ><i className="fi flaticon-box"></i><span>Packages</span></Link>
                                </li>
                            }
                            {
                                this.props && this.props.userPermission && this.props.userPermission.length > 0 && this.props.userPermission.includes('/transactions') &&
                                <li onClick={this.activeTab.bind(this, 'Transaction')} className={tab == 'Transaction' ? "nav-item active" : "nav-item"}>
                                    <Link className="nav-link" to="/transactions" ><i className="fi flaticon-data-transfer"></i><span>Transactions</span></Link>
                                </li>
                            }
                            {
                                this.props && this.props.userPermission && this.props.userPermission.length > 0 && this.props.userPermission.includes('/report') &&
                                <li onClick={this.activeTab.bind(this, 'Report')} className={tab == 'Report' ? "nav-item active" : "nav-item"}>
                                    <Link className="nav-link" to="/report" ><i className="fi flaticon-file"></i><span>Reports</span></Link>
                                </li>
                            }
                            {
                                this.props && this.props.userPermission && this.props.userPermission.length > 0 && this.props.userPermission.includes('/customers') &&
                                <li onClick={this.activeTab.bind(this, 'Customer')} className={tab == 'Customer' ? "nav-item active" : "nav-item"}>
                                    <Link className="nav-link" to="/customers"><i className="fi flaticon-customer"></i><span>Customers</span></Link>
                                </li>
                            }
                            {
                                this.props && this.props.userPermission && this.props.userPermission.length > 0 && this.props.userPermission.includes('/user') &&
                                <li onClick={this.activeTab.bind(this, 'User')} className={tab == 'User' ? "nav-item active" : "nav-item"}>
                                    <Link className="nav-link" to="/user" ><i className="fi flaticon-profile"></i><span>User management</span></Link>
                                </li>
                            }
                        </ul>
                    </div>
                </div>

            </aside>
        )
    }
}

export default Sidebar