import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Form, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText, } from 'reactstrap';
import randomstring from 'randomstring';
import { getVendorDetails, getVendors } from '../../../../api/vendor';
import { addVoucher, editVoucher } from '../../../../api/voucher';
import { getCognitoToken } from '../../../../api/aws';
import { setBucket } from '../../../../aws/awsupload'
import './index.css';
import { getCategory } from '../../../../api/category';
import MultiSelect from 'react-multi-select-component';

class AddOrEditVoucher extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            offer: "",
            offerDetail: "",
            vendorId: 0,
            imageURL: "",
            tags: [""],
            total: 0,
            vendorList: [],
            total: 0,
            status: "inactive",
            usedVouchers: 0,
            imageURL: '',
            imageFile: '',
            imageFileName: '',
            imageChanged: false,
            cognitoToken: '',
            identityID: '',
            loading: false,
            categoryOptions: [],
            selectedCategories: [],
        }
    }

    componentDidMount = () => {
        const params = {
            count: 50,
            status: "active"
        }
        getVendors(params).then((response) => {
            this.setState({
                vendorList: response.data.vendors,
            })
        })
        getCategory(params).then((response) => {
            let categoryOptions = []
            response.data.categories.forEach(category => {
                categoryOptions.push({
                    label: category.name,
                    value: category.id
                })
            });
            this.setState({
                categoryOptions,
            })
            this.fullCategoryOptions = categoryOptions
        }).catch((error) => {
            console.log("Error listing categories ", error)
            this.props.setAlert(error.message || "An unexpected error occurred", "danger")
        })
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.voucher && this.props.voucher.id && this.props.voucher.id != 0 && prevProps.voucher.id != this.props.voucher.id) {
            const voucher = this.props.voucher
            this.setState({
                offer: voucher.offer.replace('%', ''),
                offerDetail: voucher.offerDetail,
                vendorId: voucher.vendorId,
                usedVouchers: voucher.redeemedCount,
                total: voucher.total,
                tags: voucher.tags,
                status: voucher.active ? "active" : "inactive",
                imageURL: voucher.voucherImage,
                imageFileName: '',
                imageChanged: false,
                cognitoToken: '',
                identityID: '',
                loading: false
            }, () => {
                this.loadSelectedCategories()
                this.loadCategoryOptions(this.state.vendorId)
            })
        }
        if (this.props.mode != prevProps.mode && this.props.mode == "add") {
            this.emptyState()
        }
    }

    loadCategoryOptions = (vendorId) => {
        let categoryOptions = []
        if (vendorId) {
            getVendorDetails(vendorId).then((response) => {
                response.data.categories.forEach(category => {
                    categoryOptions.push({
                        label: category.name,
                        value: category.id
                    })
                })
                this.setState({
                    categoryOptions,
                })
            }).catch((error) => {
                console.log("Error while loading category options ", error)
                this.props.setAlert(error.message || "An unexpected error occurred", "danger")
            })
        } else {
            this.setState({
                categoryOptions: this.fullCategoryOptions
            })
        }
    }

    loadSelectedCategories = () => {
        let selectedCategories = []
        this.props.voucher.categories.forEach(category => {
            selectedCategories.push({
                label: category.name,
                value: category.id
            })
        });
        this.setState({
            selectedCategories,
        })
    }

    emptyState = () => {
        this.setState({
            offer: "",
            offerDetail: "",
            vendorId: 0,
            usedVouchers: "",
            total: 0,
            tags: [""],
            status: "inactive",
            imageURL: "",
            imageFileName: '',
            imageChanged: false,
            cognitoToken: '',
            identityID: '',
            loading: false,
            selectedCategories: [],
        })
        this.loadCategoryOptions()
    }

    handleImageChange = (event) => {
        this.setState({
            imageURL: URL.createObjectURL(event.target.files[0]),
            imageFile: event.target.files[0],
            imageFileName: randomstring.generate() + "." + event.target.files[0].type.split('/')[1],
            imageChanged: true
        })
    }

    addMoreTags = () => {
        this.setState({
            tags: [...this.state.tags, ""]
        })
    }

    changeValue = (key, value) => {
        this.setState({
            [key]: value
        })
        if (key == "vendorId") {
            this.loadCategoryOptions(value)
        }
    }

    selectCategories = (selectedCategories) => {
        this.setState({
            selectedCategories,
        })
    }

    changeTags = (index, tag) => {
        let tags = this.state.tags.slice()
        tags[index] = tag
        this.setState({
            tags,
        })
    }

    removeTag = (index) => {
        let tags = this.state.tags.slice()
        tags.splice(index, 1)
        this.setState({
            tags,
        })
    }

    submit = () => {
        this.setState({
            loading: true
        }, () => {
            const {
                offer,
                offerDetail,
                total,
                vendorId,
                status,
                imageFileName,
                selectedCategories
            } = this.state
            let tags = this.state.tags.slice()
            let validTags = tags.filter((tag) => tag != "")
            let valid = true
            let alertMessage = ""

            if (!offer || offer > 100 || offer < 0) {
                valid = false
                alertMessage = "Enter a valid offer"
            }
            if (!offerDetail) {
                valid = false
                alertMessage = "Enter offer details"
            }
            if (!total || total < 0) {
                valid = false
                alertMessage = "Enter a valid total voucher count"
            }
            if (!vendorId) {
                valid = false
                alertMessage = "Select a vendor"
            }
            if (!tags.length) {
                valid = false
                alertMessage = "Select atleast one tag"
            }

            if (selectedCategories.length == 0) {
                valid = false
                alertMessage = "Please select atleast one category"
            }

            if (!valid) {
                this.props.setAlert(alertMessage, "danger")
                this.setState({
                    loading: false
                })
                return
            }

            let category = []
            selectedCategories.forEach(selectedCategory => {
                category.push(selectedCategory.value)
            });

            const body = {
                offer: offer + "%",
                offerDetail,
                vendorId,
                tags: validTags,
                total,
                category,
            }

            if (this.props.mode == "edit") {
                body.status = status
            }

            let bucket = {}
            if (this.props.mode == "add" || this.state.imageChanged) {
                if (!imageFileName) {
                    this.props.setAlert("Choose a voucher image", "danger")
                    this.setState({
                        loading: false
                    })
                    return
                }
                body.imageURL = 'voucher/' + imageFileName
                let params = {}
                params.identityId = ""
                getCognitoToken(params).then((response) => {
                    this.setState({
                        cognitoToken: response.data.cognitoToken,
                        identityID: response.data.identityId
                    }, () => {
                        // set S3 bucket after retreiving token successfully
                        bucket = setBucket(this.state.cognitoToken)

                        let file = this.state.imageFile
                        let fileKey = `voucher/${imageFileName}`;
                        let params = {
                            Key: fileKey,
                            ContentType: file.type,
                            Body: file,
                            ACL: 'public-read',
                        };
                        bucket.putObject(params).on('httpUploadProgress', (progress) => {
                            // console.log("progress", progress.loaded * 100 / progress.total)
                            if (parseInt((progress.loaded * 100) / progress.total) === 100) {
                                this.submitVoucherAPI(body)
                            }
                        }).send((err) => {
                            if (err) {
                                this.props.setAlert("An error occured during image upload", "danger")
                                console.log("Error while uploading video to s3 bucket in add category ", err)
                                this.setState({
                                    loading: false
                                })
                            }
                        })
                    })
                }).catch((error) => {
                    this.props.setAlert("An error occured during image upload", "danger")
                    console.log("An error occured during image upload ", error)
                    this.setState({
                        loading: false
                    })
                })
            } else {
                this.submitVoucherAPI(body)
            }
        })
    }

    submitVoucherAPI = (body) => {
        if (this.props.mode == "add") {
            addVoucher(body).then(() => {
                this.emptyState()
                this.props.toggle()
                this.props.setAlert("Voucher added successfully", "success")
                this.props.getVoucherList()
            }).catch((error) => {
                this.props.setAlert(error.message, "danger")
                this.setState({
                    loading: false
                })
            })
        } else {
            editVoucher(this.props.voucher.id, body).then(() => {
                this.emptyState()
                this.props.toggle()
                this.props.setAlert("Voucher edited successfully", "success")
                this.props.getVoucherList()
            }).catch((error) => {
                this.props.setAlert(error.message, "danger")
                this.setState({
                    loading: false
                })
            })
        }
    }

    render = () => {
        const { vendorList, offerDetail, offer, vendorId, imageURL, tags, total, usedVouchers, status, loading, categoryOptions, selectedCategories } = this.state;
        return (
            <Modal isOpen={this.props.isOpen} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }}>
                <ModalHeader toggle={this.props.toggle}>{this.props.mode == "add" ? "Add Voucher" : "Edit Voucher"}</ModalHeader>
                <ModalBody>
                    <Form className="row">
                        <FormGroup className="col-3">
                            <Label for="offer">Offer</Label>
                            <InputGroup>
                                <Input name="offer" id="offer" type="number" onChange={(e) => this.changeValue("offer", e.target.value)} value={offer} />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>%</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className="col-9">
                            <Label for="vendorSelect">Vendor</Label>
                            <Input type="select" name="select" id="vendorSelect" value={vendorId} onChange={(e) => this.changeValue("vendorId", parseInt(e.target.value))}>
                                <option value={-1}>Select a vendor</option>
                                {
                                    vendorList.length > 0 &&
                                    vendorList.map((vendor) => {
                                        return (
                                            <option key={vendor.id} value={vendor.id}>{vendor.name}</option>
                                        )
                                    })
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup className="col-12">
                            <Label for="offerDetails">Offer Details</Label>
                            <Input type="textarea" name="offerDetails" id="offerDetails" onChange={(e) => this.changeValue("offerDetail", e.target.value)} value={offerDetail} />
                        </FormGroup>
                        <FormGroup className="col-12">
                            <Label for="categories">Select Categories</Label>
                            <MultiSelect
                                options={categoryOptions}
                                value={selectedCategories}
                                onChange={this.selectCategories}
                                labelledBy={"Select"}
                            />
                        </FormGroup>
                        {
                            this.props.mode == "edit" &&
                            <FormGroup className="col-6">
                                <Label for="statusSelect">Status</Label>
                                <Input type="select" name="select" id="statusSelect" value={status} onChange={(e) => this.changeValue("status", e.target.value)}>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </Input>
                            </FormGroup>
                        }
                        <FormGroup className="col-6">
                            <Label for="totalVouchers">Total Vouchers</Label>
                            <Input name="totalVouchers" id="totalVouchers" type="number" onChange={(e) => this.changeValue("total", parseInt(e.target.value))} value={total} />
                            {/* <Col xs="6" className="p-2">
                                    <div>
                                        Number of Vouchers Used {usedVouchers || "0"}
                                    </div>
                                </Col> */}
                        </FormGroup>
                        <FormGroup className="col-12">
                            <Label for="image">Image</Label>
                            <span className="card uploadImgwrap2 d-flex align-items-center justify-content-center position-relative">
                                <Input name="image" accept="image/*" type="file" placeholder="Select image" onChange={this.handleImageChange} className="addModalChooseImage h-100" />
                                {
                                    imageURL &&
                                    <img className="mx-auto py-3" src={imageURL} alt="" />
                                }
                            </span>
                            <small className="d-flex mt-3">Please click on the above image to edit</small>
                        </FormGroup>
                        <FormGroup className="col-12">
                            <Label for="tags">Tags</Label>
                            {
                                tags.length > 0 &&
                                tags.map((tag, index) => {
                                    return (
                                        <InputGroup className="mb-2" key={index}>
                                            <Input type="text" name="tags" id={`tags-${index}`} placeholder="Enter a keyword related to this voucher" onChange={(e) => this.changeTags(index, e.target.value)} value={tag} />
                                            <InputGroupAddon addonType="append"><Button color="secondary" onClick={() => this.removeTag(index)}>-</Button></InputGroupAddon>
                                        </InputGroup>
                                    )
                                })
                            }
                            <Button color="info" className="mb-3" onClick={this.addMoreTags}>More Tags</Button>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.submit} disabled={loading}>Submit</Button>
                    <Button color="secondary" className="ml-2" onClick={this.props.toggle} disabled={loading}>Cancel</Button>
                </ModalFooter>
            </Modal >
        )
    }
}

export default AddOrEditVoucher;