import React, { Component } from 'react'
import CommonPagination from '../../../commonComponents/CommonPagination';
import { getPackageReport } from '../../../../api/report';
import PreviewPackage from './Preview';
import moment from 'moment'

class Package extends Component {

    constructor(props) {
        super(props);
        this.state = {
            packages: [],
            total: 0,
            noOfPages: 0,
            perPage: 0,
            selectedPage: 1,
            previewModal: false
        }
    }

    componentDidUpdate = (prevProps) => {
        if ((this.props.fromDate != prevProps.fromDate) || (this.props.toDate != prevProps.toDate)) {
            this.getPackage(this.props.fromDate, this.props.toDate, 1, true)
        }
    }

    getPackage = (fromDate, toDate, page, limit) => {
        let params = {
            fromDate,
            toDate: moment(toDate).add(1, 'd').format("YYYY-MM-DD"),
            page,
            limit
        }
        getPackageReport(params).then((response) => {
            this.setState({
                packages: response.data.package,
                total: response.data.total,
                noOfPages: response.data.noOfPages,
                perPage: response.data.perPage,
                selectedPage: page
            })
        })
    }

    handlePageClick = (page) => {
        this.getPackage(this.props.fromDate, this.props.toDate, page.selected + 1, true)
    }

    previewPdf = () => {
        this.getPackage(this.props.fromDate, this.props.toDate, 1)
        this.togglePreviewModal()
    }

    togglePreviewModal = () => {
        this.setState(prevState => ({
            previewModal: !prevState.previewModal
        }))
    }

    render() {

        const { packages, noOfPages, perPage, selectedPage, previewModal } = this.state

        return (
            <div className="card">
                <div className="card-header p-3 d-flex justify-content-between align-items-center">
                    <h5 className="m-0">Package Report</h5>
                    <button className={packages ? "btn btn-primary bg-rust ml-4" : "btn btn-primary bg-rust ml-4 disabled"} onClick={this.previewPdf}>Download PDF</button>
                </div>
                <div className="card-body p-0">
                    {
                        packages && packages.length > 0 &&
                        <table className="table mb-0 table-layout-fixed">
                            <thead>
                                <tr>
                                    <th scope="col" width="10%">No</th>
                                    <th scope="col">Package</th>
                                    <th scope="col">Customer Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    packages.map((element, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{perPage * (selectedPage - 1) + (index + 1)}</td>
                                                <td>{element.name}</td>
                                                <td>{element.count}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    }
                    {
                        !packages &&
                        <h6 className="text-center mt-3">There are no package purchases on this day/selected date range.</h6>
                    }
                    <CommonPagination onPageChange={this.handlePageClick} pageCount={noOfPages} selectedPage={selectedPage} />
                </div>
                <PreviewPackage packages={packages} modal={previewModal} toggle={this.togglePreviewModal} fromDate={this.props.fromDate} toDate={this.props.toDate} />
            </div>
        )
    }
}

export default Package