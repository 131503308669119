import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { getCustomers } from '../../../api/customer';
import CommonAlert from '../../commonComponents/CommonAlert';
import CommonPagination from '../../commonComponents/CommonPagination';
import { Input, Label } from 'reactstrap';
import moment from 'moment';

class Customer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customers: [],
            alertMessage: "",
            alertColor: "",
            alertShow: false,
            noOfPages: 0,
            perPage: 0,
            selectedPage: 1,
            searchedName: "",
            searchedEmail: "",
            searchedStatus: "",
        }
    }

    componentDidMount = () => {
        this.getCustomerList()
    }

    getCustomerList = (page = this.state.selectedPage, name = this.state.searchedName, status = this.state.searchedStatus, email = this.state.searchedEmail) => {
        let params = {
            page,
            name,
            status,
            email,
            count: 15
        }
        getCustomers(params).then((response) => {
            this.setState({
                customers: response.data.customers,
                noOfPages: response.data.noOfPages,
                perPage: response.data.perPage,
                selectedPage: page
            })
        }).catch((error) => {
            this.setAlert(error.message, "danger")
        })
    }


    setAlert = (alertMessage, alertColor) => {
        this.setState({
            alertMessage,
            alertColor,
            alertShow: true,
        }, () => {
            setTimeout(() => {
                this.setState({
                    alertMessage: "",
                    alertColor: "",
                    alertShow: false,
                })
            }, 3000)
        })
    }

    handlePageClick = (page) => {
        this.getCustomerList(page.selected + 1)
    }

    search = (key, value) => {
        this.setState({
            [key]: value
        }, () => {
            this.getCustomerList(1)
        })
    }

    render() {
        const { customers, alertMessage, alertColor, alertShow, noOfPages, selectedPage, perPage } = this.state
        return (
            <main id="main">
                <CommonAlert message={alertMessage} color={alertColor} show={alertShow} />
                <section className="content-wrapper box-shadow">
                    <div className="one-column-right h-100">
                        <div className="box radius-10 tab-style2 h-100">
                            <div className="box-header px-3 pt-4 pb-3">
                                <div className="y-center">
                                    <div className="col pl-0">
                                        <div className="d-flex align-items-center">
                                            <h5 className="pl-2">Manage Customers</h5>
                                        </div>
                                    </div>
                                    <div className="col-auto px-2">
                                        <div className="form-row align-items-center">
                                            <div className="form-group search position-relative mb-0 mr-3">
                                                <div className="d-flex align-items-center">
                                                    <input className="form-control pr-4 mr-3" type="search" placeholder="Search by name"
                                                        aria-label="Search" onChange={(e) => this.search("searchedName", e.target.value)} />
                                                    <input className="form-control pr-4 mr-3" type="search" placeholder="Search by email"
                                                        aria-label="Search" onChange={(e) => this.search("searchedEmail", e.target.value)} />
                                                    {/* <i className="fi flaticon-search position-absolute"></i> */}
                                                    {/* <Label for="statusSelect" className="m-0 mr-2">Status</Label> */}
                                                    <Input type="select" name="select" id="statusSelect" onChange={(e) => this.search("searchedStatus", e.target.value)}>
                                                        <option value="">Status All</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </Input>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body px-3">
                                <div className="card border-0">
                                    <div className="card-body p-0">
                                        <div className="table-responsive radius-10 border scroll">
                                            <table className="table mb-0 table-layout-fixed">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" width="5%">No</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Registered Time</th>
                                                        <th scope="col">Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        customers && customers.length > 0 &&
                                                        customers.map((customer, index) => {
                                                            return (
                                                                <tr key={customer.id}>
                                                                    <td>{perPage * (selectedPage - 1) + (index + 1)}</td>
                                                                    <td>{customer.name}</td>
                                                                    <td>{customer.email}</td>
                                                                    <td>{customer.active ? "Active" : "Inactive"}</td>
                                                                    <td>{moment(customer.registeredTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                                    <td>
                                                                        <Link className="btn btn-primary bg-rust px-2" to={`/customer/${customer.id}`}>
                                                                            View
                                                                            </Link>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        customers && customers.length == 0 &&
                                                        <tr>
                                                            <td colSpan="8" className="text-center">
                                                                <h6 className="mt-3">No customers are added</h6>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <CommonPagination onPageChange={this.handlePageClick} pageCount={noOfPages} selectedPage={selectedPage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}

export default Customer