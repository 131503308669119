import React, { Component } from 'react'
import { getVouchers } from '../../../api/voucher';
import CommonAlert from '../../commonComponents/CommonAlert';
import CommonPagination from '../../commonComponents/CommonPagination';
import AddOrEditVoucher from './AddEditVoucher';
import { getCategory } from '../../../api/category';
import LandingPageOffers from './LandingPageOffers';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, ListGroup, ListGroupItem } from 'reactstrap';
import './index.css';

class Vouchers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vouchers: [],
            alertMessage: "",
            alertColor: "",
            alertShow: false,
            addOrEditModalShow: false,
            addOrEditModalMode: "",
            editVoucher: {},
            noOfPages: 0,
            selectedPage: 1,
            status: "",
            perPage: 0,
            searchKey: "",
            categories: [],
            categoryId: 0,
            landingPageOffersModalShow: false,
            openCategory: 0,
        }
    }

    componentDidMount = () => {
        this.getVoucherList()
        this.getCategories()
    }

    getVoucherList = (page = this.state.selectedPage, searchKey = this.state.searchKey, categoryId = this.state.categoryId, status = this.state.status) => {
        let params = {
            page,
            searchKey,
            categoryId,
            status,
            count: 15,
        }
        getVouchers(params).then((response) => {
            this.setState({
                vouchers: response.data.vouchers,
                noOfPages: response.data.noOfPages,
                perPage: response.data.perPage,
                selectedPage: page
            })
        }).catch((error) => {
            this.setAlert(error.message, "danger")
        })
    }

    getCategories = () => {
        let params = {
            status: "active",
            count: 50,
        }
        getCategory(params).then((response) => {
            this.setState({
                categories: response.data.categories
            })
        })
    }

    resetAddOrEditModal = () => {
        this.setState({
            addOrEditModalShow: false,
            addOrEditModalMode: "",
            editVoucher: {},
        })
    }

    setModal = (addOrEditModalMode, editVoucher = {}) => {
        this.setState({
            addOrEditModalMode,
            editVoucher,
            addOrEditModalShow: true
        })
    }

    landingPageOffersModalToggle = (landingPageOffersModalShow = false) => {
        this.setState({
            landingPageOffersModalShow,
        })
    }

    setAlert = (alertMessage, alertColor) => {
        this.setState({
            alertMessage,
            alertColor,
            alertShow: true,
        }, () => {
            setTimeout(() => {
                this.setState({
                    alertMessage: "",
                    alertColor: "",
                    alertShow: false,
                })
            }, 3000)
        })
    }

    handlePageClick = (page) => {
        this.getVoucherList(page.selected + 1)
    }

    voucherSearch = (e) => {
        this.setState({
            searchKey: e.target.value
        }, () => {
            this.getVoucherList(1)
        })
    }

    searchByCategory = (id) => {
        this.setState({
            categoryId: id
        }, () => {
            this.getVoucherList(1)
        })
    }

    searchByStatus = (status) => {
        this.setState({
            status,
        }, () => {
            this.getVoucherList(1)
        })
    }

    categoryOpen = (category) => {
        let openCategory = 0
        if (this.state.openCategory != category) {
            openCategory = category
        }
        this.setState({
            openCategory,
        })
    }

    render() {
        const { vouchers, alertMessage, alertColor, alertShow, addOrEditModalMode, addOrEditModalShow, editVoucher, noOfPages, selectedPage, searchKey, perPage, categories, categoryId, landingPageOffersModalShow, status, openCategory } = this.state
        return (
            <main id="main">
                <LandingPageOffers show={landingPageOffersModalShow} toggle={() => this.landingPageOffersModalToggle(false)} />
                <AddOrEditVoucher isOpen={addOrEditModalShow} mode={addOrEditModalMode} toggle={this.resetAddOrEditModal} voucher={editVoucher} setAlert={this.setAlert} getVoucherList={this.getVoucherList} />
                <section className="content-wrapper box-shadow">
                    <div className="one-column-right h-100">
                        <div className="box radius-10 tab-style2 h-100">
                            <CommonAlert color={alertColor} show={alertShow} message={alertMessage} />
                            <div className="box-header px-3 pt-4 pb-3">
                                <div className="y-center">
                                    <div className="col pl-0">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h5 className="pl-2">Manage Vouchers</h5>
                                            <UncontrolledDropdown>
                                                <DropdownToggle caret color="primary">
                                                    Action
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem header>Manage Vouchers</DropdownItem>
                                                    <DropdownItem onClick={() => { this.setModal("add") }}>
                                                        <i className="fi flaticon-add small fs-10"></i>
                                                        <span className="pl-2">Add Vouchers</span>
                                                    </DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem onClick={() => { this.landingPageOffersModalToggle(true) }}>
                                                        <span className="pl-2">Landing Page Offers</span>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                    <div className="col-auto px-2">
                                        <div className="form-row align-items-center">
                                            <div className="form-group search position-relative mb-0 mr-3">
                                                <input className="form-control pr-4" type="search" placeholder="Search by tag"
                                                    aria-label="Search" onChange={this.voucherSearch} value={searchKey} />
                                                <i className="fi flaticon-search position-absolute"></i>
                                            </div>
                                            <div className="form-group form-inline mb-0 mr-3">
                                                <select className="form-control" value={categoryId} onChange={(e) => this.searchByCategory(e.target.value)}>
                                                    <option value="">Search by category</option>
                                                    {
                                                        categories && categories.length > 0 &&
                                                        categories.map((category, index) => {
                                                            return (
                                                                <option key={index} value={category.id}>{category.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group form-inline mb-0 mr-3 status-search">
                                                <select className="form-control" value={status} onChange={(e) => this.searchByStatus(e.target.value)}>
                                                    <option value="">Status - All</option>
                                                    <option value="active">Status - Active</option>
                                                    <option value="inactive">Status - Inactive</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body px-3">
                                <div className="card border-0">
                                    <div className="card-body p-0">
                                        <div className="table-responsive radius-10 border scroll">
                                            <table className="table mb-0 table-layout-fixed">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" width="5%">No</th>
                                                        <th scope="col">Vendor</th>
                                                        <th scope="col">Offer</th>
                                                        <th scope="col">Total</th>
                                                        <th scope="col">Redeemed Count</th>
                                                        <th scope="col">Categories</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Edit</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        vouchers && vouchers.length > 0 &&
                                                        vouchers.map((voucher, index) => {
                                                            return (
                                                                <tr key={voucher.id}>
                                                                    <td>{perPage * (selectedPage - 1) + (index + 1)}</td>
                                                                    <td>{voucher.vendor}</td>
                                                                    <td>{voucher.offer}</td>
                                                                    <td>{voucher.total}</td>
                                                                    <td>{voucher.redeemedCount}</td>
                                                                    <td className="collapse-td">
                                                                        <Button color="primary" onClick={() => this.categoryOpen(voucher.id)}>View</Button>
                                                                        <div className={"categories-collapse" + (!(voucher.id == openCategory) ? " collapse" : "")}>
                                                                            <ListGroup>
                                                                                {
                                                                                    voucher.categories && voucher.categories.length > 0 &&
                                                                                    voucher.categories.map((category) => {
                                                                                        return (
                                                                                            <ListGroupItem key={category.id}>{category.name}</ListGroupItem>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ListGroup>
                                                                        </div>
                                                                    </td>
                                                                    <td>{voucher.active ? "Active" : "Inactive"}</td>
                                                                    <td className="c-clay-creek edit"><i onClick={() => this.setModal("edit", voucher)} className="fi flaticon-write"></i></td>

                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        vouchers && vouchers.length == 0 &&
                                                        <tr>
                                                            <td colSpan="8" className="text-center">
                                                                <h6 className="mt-3">No vouchers are added</h6>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <CommonPagination onPageChange={this.handlePageClick} pageCount={noOfPages} selectedPage={selectedPage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </main >
        )
    }
}

export default Vouchers