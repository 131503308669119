import React, { Component } from 'react'
import CommonPagination from '../../../commonComponents/CommonPagination';
import { getCategoryReport } from '../../../../api/report'
import PreviewCategory from './Preview';
import moment from 'moment'


class Category extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            total: 0,
            noOfPages: 0,
            perPage: 0,
            selectedPage: 1,
            previewModal: false
        }
    }


    componentDidUpdate = (prevProps) => {
        if ((this.props.fromDate != prevProps.fromDate) || (this.props.toDate != prevProps.toDate)) {
            this.getCategory(this.props.fromDate, this.props.toDate, 1, true)
        }
    }

    getCategory = (fromDate, toDate, page, limit) => {
        let params = {
            fromDate,
            toDate: moment(toDate).add(1, 'd').format("YYYY-MM-DD"),
            page,
            limit
        }
        getCategoryReport(params).then((response) => {
            this.setState({
                categories: response.data.category,
                total: response.data.total,
                noOfPages: response.data.noOfPages,
                perPage: response.data.perPage,
                selectedPage: page
            })
        })
    }

    handlePageClick = (page) => {
        this.getCategory(this.props.fromDate, this.props.toDate, page.selected + 1, true)
    }

    previewPdf = () => {
        this.getCategory(this.props.fromDate, this.props.toDate, 1)
        this.togglePreviewModal()
    }

    togglePreviewModal = () => {
        this.setState(prevState => ({
            previewModal: !prevState.previewModal
        }))
    }

    render() {
        const { categories, perPage, selectedPage, noOfPages, previewModal } = this.state
        return (
            <div className="card">
                <div className="card-header p-3 d-flex justify-content-between align-items-center">
                    <h5 className="m-0">Category Report</h5>
                    <button className={categories ? "btn btn-primary bg-rust ml-4" : "btn btn-primary bg-rust ml-4 disabled"} onClick={this.previewPdf}>Download PDF</button>
                </div>
                <div className="card-body p-0" >
                    {
                        categories && categories.length > 0 &&
                        <table className="table mb-0 table-layout-fixed" >
                            <thead>
                                <tr>
                                    <th scope="col" width="10%">No</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">Voucher Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    categories.map((voucher, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{perPage * (selectedPage - 1) + (index + 1)}</td>
                                                <td>{voucher.name}</td>
                                                <td>{voucher.count}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    }
                    {
                        !categories &&
                        <h6 className="text-center mt-3">There are no redemption on this day/selected date range.</h6>
                    }
                    <CommonPagination onPageChange={this.handlePageClick} pageCount={noOfPages} selectedPage={selectedPage} />
                </div>
                <PreviewCategory categories={categories} modal={previewModal} toggle={this.togglePreviewModal} fromDate={this.props.fromDate} toDate={this.props.toDate} />
            </div>
        )
    }
}

export default Category