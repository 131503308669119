import React, { Component } from 'react'

class Unauthorized extends Component {
    render() {
        return (
            <main id="main">
                <section className="content-wrapper">
                    <div className="box h-100 xy-center flex-column box-shadow radius-10">
                        <h1>Unauthorized</h1>
                    </div>
                </section>
            </main>
        )
    }
}

export default Unauthorized