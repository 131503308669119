import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import { resetPassword } from '../../../../api/user';

class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            confirmPassword: '',
            buttonDisabled: false
        }
    }

    onInputChange = (value, key) => {
        this.setState({
            [key]: value
        })
    }

    componentDidUpdate = (prevProps) => {
        if (this.props != prevProps) {
            this.setState({

            })
        }
    }

    submitResetPassword = () => {
        this.setState({
            buttonDisabled: true
        }, () => {
            const { newPassword, confirmPassword } = this.state
            let valid = true
            let message = []
            let passwordMatch = ''

            if (!newPassword) {
                valid = false
                message.push('new password')
            }
            if (!confirmPassword) {
                valid = false
                message.push('confirm password')
            }
            if ((newPassword && confirmPassword) && (newPassword != confirmPassword)) {
                valid = false
                passwordMatch = 'New and confirm password does not match'
            }

            if (!valid) {
                let alertMessage = (message.length > 0 ? 'Please enter valid ' + message.join(',') : passwordMatch)
                this.props.showAlert(alertMessage, 'danger')
                this.setState({
                    buttonDisabled: false
                })
                return
            }

            let body = {
                password: confirmPassword
            }

            resetPassword(this.props && this.props.userId, body).then((response) => {
                if (response) {
                    this.props.toggle()
                    this.props.showAlert(response.message, 'success')
                }
            }).catch((error) => {
                this.props.showAlert(error.message, 'danger')
            })
        })
    }

    render() {

        const { newPassword, confirmPassword, buttonDisabled } = this.state

        return (
            <Modal isOpen={this.props.modal}>
                <ModalHeader toggle={this.props.toggle}>Reset Password</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="newPassword">New Password</Label>
                            <Input name="newPassword" id="newPassword" type="password" value={newPassword} onChange={(e) => this.onInputChange(e.target.value, 'newPassword')} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="confirmPassword">Confirm Password</Label>
                            <Input name="confirmPassword" id="confirmPassword" type="password" value={confirmPassword} onChange={(e) => this.onInputChange(e.target.value, 'confirmPassword')} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.submitResetPassword} disabled={buttonDisabled} className="mr-2">Submit</Button>
                    <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default ResetPassword