import React, { Component } from 'react'
import { getUsers, getAllPermissions, deleteUser } from '../../../api/user';
import AddUser from './Add';
import EditUser from './Edit';
import DeleteUser from './Delete';
import CommonPagination from '../../commonComponents/CommonPagination'
import CommonAlert from '../../commonComponents/CommonAlert';
import ResetPassword from './Reset';

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            total: 0,
            noOfPages: 0,
            perPage: 0,
            selectedPage: 1,
            permissions: [],
            searchKey: '',
            addUserModal: false,
            editUser: {},
            editUserModal: false,
            deleteUserId: 0,
            deleteUserModal: false,
            message: '',
            messageType: '',
            resetPasswordUserId: -1,
            resetPasswordModal: false
        }
    }

    componentDidMount = () => {
        this.listUsers()
        this.listPermissions()
    }

    searchUser = (searchKey) => {
        this.setState({
            searchKey,
        }, () => {
            this.listUsers(1)
        })
    }

    updateList = (message, messageType) => {
        this.listUsers()
        this.setAlert(message, messageType)
    }

    dismissAlert = () => {
        this.setState({
            message: '',
            messageType: ''
        })
    }

    listUsers = (page = this.state.selectedPage, title = this.state.searchKey) => {
        let params = {
            title,
            page,
            count: 15
        }
        getUsers(params).then((response) => {
            this.setState({
                users: response.data.users,
                total: response.data.total,
                noOfPages: response.data.noOfPages,
                perPage: response.data.perPage,
                selectedPage: page
            })
        })
    }

    listPermissions = () => {
        getAllPermissions().then((response) => {
            this.setState({
                permissions: response.data
            })
        })
    }

    toggleAddUserModal = () => {
        this.setState(prevState => ({
            addUserModal: !prevState.addUserModal
        }))
    }

    editUserDetails = (editUser) => {
        this.setState({
            editUser,
        }, () => {
            this.toggleEditUserModal()
        })
    }

    toggleEditUserModal = () => {
        this.setState(prevState => ({
            editUserModal: !prevState.editUserModal
        }))
    }

    deleteUserDetails = (deleteUserId) => {
        this.setState({
            deleteUserId,
        }, () => {
            this.toggleDeleteUserModal()
        })
    }

    toggleDeleteUserModal = () => {
        this.setState(prevState => ({
            deleteUserModal: !prevState.deleteUserModal
        }))
    }

    submitDeleteUser = () => {
        deleteUser(this.state.deleteUserId).then((response) => {
            this.toggleDeleteUserModal()
            this.updateList(response.message, 'success')
        }).catch((error) => {
            this.setAlert(error.message, 'danger')
        })
    }

    handlePageClick = (page) => {
        this.listUsers(page.selected + 1)
    }

    setAlert = (message, messageType) => {
        this.setState({
            message,
            messageType
        }, () => {
            setTimeout(this.dismissAlert, 3000);
        })
    }

    resetPassword = (id) => {
        this.setState({
            resetPasswordUserId: id
        }, () => {
            this.toggleResetPasswordModal()
        })
    }

    toggleResetPasswordModal = () => {
        this.setState(prevState => ({
            resetPasswordModal: !prevState.resetPasswordModal
        }))
    }

    render() {

        const { users, permissions, searchKey, selectedPage, perPage, noOfPages, addUserModal, editUserModal, editUser, deleteUserModal, message, messageType, resetPasswordModal, resetPasswordUserId } = this.state
        return (
            <main id="main">
                <section className="content-wrapper box-shadow">
                    <div className="one-column-right h-100">
                        <div className="box radius-10 tab-style2 h-100">
                            <CommonAlert color={messageType} show={!!messageType} message={message} />
                            <div className="box-header px-3 pt-4 pb-3">
                                <div className="y-center">
                                    <div className="col pl-0">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h5 className="pl-2">Manage Users</h5>
                                            <button className="btn btn-primary bg-rust ml-4" onClick={this.toggleAddUserModal} >
                                                <i className="fi flaticon-add small fs-10"></i>
                                                <span className="pl-2">Add User</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-auto px-2">
                                        <div className="form-row align-items-center">
                                            <div className="form-group search position-relative mb-0 mr-3">
                                                <input className="form-control pr-4" type="search" placeholder="Search user" value={searchKey} onChange={(e) => this.searchUser(e.target.value)}
                                                    aria-label="Search" />
                                                <i className="fi flaticon-search position-absolute"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body px-3">
                                <div className="card border-0">
                                    <div className="card-body p-0">
                                        <div className="table-responsive radius-10 border scroll">
                                            <table className="table mb-0 table-layout-fixed">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="text-center" width="5%">No</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Role</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Password</th>
                                                        <th scope="col">Edit</th>
                                                        <th scope="col">Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        users && users.length > 0 &&
                                                        users.map((user, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="text-center">{perPage * (selectedPage - 1) + (index + 1)}</td>
                                                                    <td>{user.name}</td>
                                                                    <td>{user.email}</td>
                                                                    <td>{user.role}</td>
                                                                    <td>{user.active ? "Active" : "Inactive"}</td>
                                                                    <td className="text-line pointer" onClick={() => this.resetPassword(user.id)}>Reset</td>
                                                                    <td className="c-clay-creek edit"><i onClick={() => this.editUserDetails(user)} className="fi flaticon-write"></i></td>
                                                                    <td className="delete"><i onClick={() => this.deleteUserDetails(user.id)} className="fi c-red flaticon-delete" ></i></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        users && users.length == 0 &&
                                                        <tr>
                                                            <td colSpan="8" className="text-center">
                                                                <h6 className="mt-3">No users are added</h6>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <CommonPagination onPageChange={this.handlePageClick} pageCount={noOfPages} selectedPage={selectedPage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <AddUser modal={addUserModal} toggle={this.toggleAddUserModal} permissions={permissions} showAlert={this.updateList} />
                <EditUser modal={editUserModal} toggle={this.toggleEditUserModal} user={editUser} permissions={permissions} showAlert={this.updateList} />
                <DeleteUser modal={deleteUserModal} toggle={this.toggleDeleteUserModal} submitDeleteUser={this.submitDeleteUser} />
                <ResetPassword modal={resetPasswordModal} toggle={this.toggleResetPasswordModal} showAlert={this.updateList} userId={resetPasswordUserId} />
            </main>
        )
    }
}

export default Users