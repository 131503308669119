import React, { Component, Suspense } from 'react'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import { getUserDetails, getAllPermissions } from '../../api/user'
import Dashboard from '../pages/Dashboard'
import Navbar from '../commonComponents/Navbar'
import Sidebar from '../commonComponents/Sidebar'
import Category from '../pages/Category'
import Customer from '../pages/Customer'
import Vendor from '../pages/Vendor'
import Voucher from '../pages/Voucher'
import Package from '../pages/Package'
import Users from '../pages/User'
import CustomerDetails from '../pages/Customer/CustomerDetails'
import Unauthorized from '../pages/Unauthorized'
import Report from '../pages/Report'
import Transactions from '../pages/Transactions'

class Routing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentRoute: '',
            user: {},
            permissions: [],
            userPermission: [],
            calender: '',
            from: '',
            to: ''
        }
    }

    componentDidMount = () => {
        this.getPermissions()
    }

    changeRoute = (tab) => {
        this.setState({
            currentRoute: tab
        })
    }

    getPermissions = () => {
        getAllPermissions().then((data) => {
            this.setState({
                permissions: data.data
            }, () => {
                this.setUser()
            })
        })
    }

    setUser = () => {
        getUserDetails().then((data) => {
            this.setState({
                user: data.data
            }, () => {
                this.formatPermissionUser()
            })
        })
    }

    formatPermissionUser = () => {
        let permission = this.state.permissions
        let user = (this.state.user.permissions).split(',').map(Number)
        let userPermission = []

        user.forEach((element1) => {
            permission.forEach((element2) => {
                if (element1 == element2.id) {
                    userPermission.push(element2.route)
                }
            })
        })

        this.setState({
            userPermission: userPermission
        })
    }

    calenderStatus = (keyword) => {
        this.setState({
            calender: keyword
        })
    }

    setCalenderDates = (from, to) => {
        this.setState({
            calender: 'custom',
            from: from,
            to: to
        })
    }

    render() {
        const { currentRoute, userPermission, calender, from, to } = this.state
        return (
            <div id="body-wrapper">
                <Suspense fallback={<div>Loading...</div>}></Suspense>
                <Route path="/" render={(props) => <Sidebar {...props} changeRoute={this.changeRoute} userPermission={userPermission} />} />
                <Route path="/" render={(props) => <Navbar {...props} currentRoute={currentRoute} user={this.state.user} calenderStatus={this.calenderStatus} setCalenderDates={this.setCalenderDates} />} />
                <Switch>
                    <Route path="/dashboard" render={(props) => <Dashboard {...props} changeRoute={this.changeRoute} calender={calender} from={from} to={to} />} />
                    <Route path="/category" render={(props) => userPermission.includes('/category') ? <Category {...props} /> : <Unauthorized />} />
                    <Route path="/vendor" render={(props) => userPermission.includes('/vendor') ? <Vendor {...props} /> : <Unauthorized />} />
                    <Route path="/voucher" render={(props) => userPermission.includes('/voucher') ? <Voucher {...props} /> : <Unauthorized />} />
                    <Route path="/package" render={(props) => userPermission.includes('/package') ? <Package {...props} /> : <Unauthorized />} />
                    <Route path="/user" render={(props) => userPermission.includes('/user') ? <Users {...props} /> : <Unauthorized />} />
                    <Route path="/customers" render={(props) => userPermission.includes('/customers') ? <Customer {...props} /> : <Unauthorized />} />
                    <Route path="/customer/:id" render={(props) => userPermission.includes('/customers') ? <CustomerDetails {...props} /> : <Unauthorized />} />
                    <Route path="/report" render={(props) => userPermission.includes('/report') ? <Report /> : <Unauthorized />} />
                    <Route path="/transactions" render={(props) => userPermission.includes('/transactions') ? <Transactions /> : <Unauthorized />} />
                    <Route path="/" render={() => <Redirect to="/dashboard" />} />
                </Switch>
            </div>
        )
    }
}

export default withRouter(Routing);