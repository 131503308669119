import React, { Component } from 'react'
import nv from 'nvd3';
import d3 from 'd3';

class PieChart extends Component {

    constructor(props) {
        super(props);
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.pieChartValue != this.props.pieChartValue) {
            this.renderChart()
        }
    }

    renderChart = () => {
        let height = 350;
        let width = 350;

        d3.selectAll("svg > *").remove();

        nv.addGraph(() => {
            let chart = nv.models.pieChart()
                .x((d) => { return d.label })
                .y((d) => { return d.value })
                .valueFormat(d3.format("f"))
                .height(300)
                .showLabels(true)
                .color(['#7e86fd', '#00addd', '#00cadc', '#37e5d4', '#88eecd', '#0078ff'])
                .labelType("value")
                .margin({ top: 70 })

            d3.select("#pieChart")
                .datum(this.props.pieChartValue)
                .transition().duration(350)
                .attr('width', width)
                .attr('height', height)
                .call(chart);

            chart.pie.labelsOutside(true).donut(true)

            return chart
        })
    }


    render() {
        return (
            <svg id="pieChart"></svg>
        )
    }
}

export default PieChart